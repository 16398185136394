import classNames from 'classnames';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowLeft } from './images/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './images/ArrowRight.svg';
import { ReactComponent as CyberTalentsLogo } from './images/Cyber_Talents-01.svg';
import { ReactComponent as DevtalkLogo } from './images/DEVtalk-logo.svg';
import { ReactComponent as GolangLogo } from './images/golang_logo.svg';
import { ReactComponent as NehrapiLogo } from './images/HRAPI_podcast-logo_white.svg';
import { ReactComponent as HRApiLogo } from './images/HRApiLogo.svg';
import { ReactComponent as ITeventsLogo } from './images/IT-Events_logo.svg';
import { ReactComponent as ITcupLogo } from './images/ITC_logo.svg';
import { ReactComponent as ITCGLogo } from './images/ITCG_logo_white.svg';
import { ReactComponent as SchoolLogo } from './images/ithrschool.svg';
import { ReactComponent as MeetupLogo } from './images/meetup.svg';
import { ReactComponent as OSTLogo } from './images/OSTLogo.svg';
import s from './OtherProjects.module.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const cards = [
    {
        icon: <HRApiLogo />,
        id: 'hapi1',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Конференция</div>
                <div className={s.name}>HR API</div>
            </div>
        ),
        link: 'https://hrapiconf.com/',
    },
    {
        icon: <OSTLogo />,
        id: 'ost',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Конференция</div>
                <div className={s.name}>Open Source Technologies</div>
            </div>
        ),
        link: 'https://ostconf.com/',
    },
    {
        icon: <GolangLogo />,
        id: 'go',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Конференция</div>
                <div className={s.name}>Golang Piter</div>
            </div>
        ),
        link: 'https://golangpiter.com/',
    },
    {
        icon: <ITeventsLogo />,
        id: 'it-events',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Агрегатор</div>
                <div className={s.name}>IT-Событий</div>
            </div>
        ),
        link: 'https://it-events.com/',
    },
    {
        icon: <MeetupLogo />,
        id: 'meetup',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>IT HR</div>
                <div className={s.name}>сообщество</div>
            </div>
        ),
        link: 'https://ithrmeetup.ru/',
    },
    {
        icon: <SchoolLogo />,
        id: 'school',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>курсы</div>
                <div className={s.name}>IT HR School</div>
            </div>
        ),
        link: 'https://ithr-school.ru/',
    },
    {
        icon: <ITcupLogo />,
        id: 'it-cup',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>ЛИГА</div>
                <div className={s.name}>IT-Challenge CUP</div>
            </div>
        ),
        link: 'https://itccup.com/',
    },
    {
        icon: <ITCGLogo />,
        id: 'itc',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Чемпионат</div>
                <div className={s.name}>IT Cyber Games</div>
            </div>
        ),
        link: 'https://itcybergames.ru/',
    },
    {
        icon: <NehrapiLogo />,
        id: 'nehrapi',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>HR Подкаст</div>
                <div className={s.name}>не HRapi</div>
            </div>
        ),
        link: 'https://www.youtube.com/playlist?list=PLp2aUaz0EAxCZaCoFjdwlewrFI4323W05',
    },
    {
        icon: <DevtalkLogo />,
        id: 'devtalk',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Подкаст</div>
                <div className={s.name}>#DevTalkRu</div>
            </div>
        ),
        link: 'https://www.youtube.com/playlist?list=PL2lK53ydln6608HwnF_1Xu_LILqpYuMis',
    },
    {
        icon: <CyberTalentsLogo />,
        id: 'devtalk',
        title: (
            <div className={s.titleWrapper}>
                <div className={s.type}>Сообщество</div>
                <div className={s.name}>Cyber Talents</div>
            </div>
        ),
        link: 'https://t.me/cyber_talents',
    },
];

export default function OtherProjects() {
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <h2 className={classNames('section__title', s.sectionTitle)}>
                    Другие наши проекты
                </h2>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={88}
                    loop={true}
                    modules={[Navigation]}
                    navigation={{
                        prevEl: '.prev',
                        nextEl: '.next',
                    }}
                    className={s.swiper}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={card.id} virtualIndex={index}>
                            <a
                                href={card.link}
                                target="_blank"
                                rel="noreferrer"
                                className={s.cardWrapper}
                            >
                                <div className={s.icon}>{card.icon}</div>
                                <div className={s.title}>{card.title}</div>
                            </a>
                        </SwiperSlide>
                    ))}
                    <div className={classNames('prev', s.prev)}>
                        <ArrowLeft />
                    </div>
                    <div className={classNames('next', s.next)}>
                        <ArrowRight />
                    </div>
                </Swiper>
            </div>
        </section>
    );
}
