import { ListItem } from '../../Approach';
import { Card } from '../../CardsList';
import { DisciplineCard } from '../../DisciplinesClassic';
import { ReactComponent as CsgoIcon } from '../images/icon/CSGO.svg';
import CsgoBg from '../images/png/CSGO.png';

export const streamDisciplinesCards: DisciplineCard[] = [
    { id: 'csG1', icon: <CsgoIcon />, bg: CsgoBg, count: 32, cardContent: <></> },
];

export const typeCards: Card[] = [
    {
        front: 'Киберспорт',
        back: 'Соревнование между сотрудниками одной компании или между командами структурных подразделений',
    },
    {
        front: 'Классический спорт',
        back: 'Спортивное противостояние между игроками или командами из разных компаний, например, дружеский футбольный матч',
    },
    {
        front: 'Конфернции, митапы, подкасты',
        back: 'Хотите подогреть интерес аудитории к вашей рекламной кампании? Спортивный праздник точно привлечёт внимание, если ваша ЦА любит активный отдых',
    },
];

export const formatCards: Card[] = [
    {
        front: 'ТУРНИР',
        back: 'Состязания между командами или отдельными игроками, с турнирной таблицей и призовыми местами',
    },
    {
        front: 'ЧЕМПИОНАТ',
        back: 'Соревнование на звание чемпиона какого-либо уровня (отдела, департамента, группы компаний и т.д.) в определённой спортивной дисциплине',
    },
    {
        front: 'СПАРТАКИАДА',
        back: 'Открытые соревнования в нескольких дисциплинах сразу, в которых принимает участие большое количество людей с разным уровнем подготовки',
    },
    {
        front: 'ШОУ-МАТЧ',
        back: 'Зрелищная битва с приглашёнными звёздами: популярными стримерами, блогерами и PRO игроками',
    },
    {
        front: 'ФЕСТИВАЛЬ',
        back: 'Много спортивных активностей на разный вкус на одной площадке с мастер-классами от профи и конкурсами, для участников с любым уровнем подготовки',
    },
    {
        front: 'СПОРТИВНОЕ ШОУ',
        back: 'Большое событие с насыщенной программой: от классической акробатики и эквилибра до трюкового футбола, паркура и черлидинга',
    },
];

export const approachItems: ListItem[] = [
    { title: 'Заполнение брифинга' },
    { title: 'Подготовка коммерческого предложения' },
    { title: 'Согласование всех аспектов проведения турнира' },
    { title: 'Разработка индивидуального регламента' },
    { title: 'Определение оптимальной турнирной сетки исходят из количества участников' },
    { title: 'Коммуникация и поддержка участников на этапе подготовки и проведения' },
    { title: 'Поиск и аренда площадок' },
    { title: 'Аренда и подготовка необходимого оборудования и спортивного инвентаря' },
    { title: 'Подготовка бригады квалифицированных судей' },
    { title: 'Оформление площадки (флаги, прессволы, шатры)' },
    { title: 'Создание баннеров для социальных сетей' },
    { title: 'Разработка ленгдинга спортивного мероприятия' },
    { title: 'Написание писем, анонсов' },
    { title: 'Проведение спортивного мероприятия' },
    { title: 'Получение обратной связи Ретроспектива' },
];
