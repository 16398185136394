import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { storeLanguageLocally } from '../../helpers/lang';
import { StateContext } from '../App/App';
import s from './Header.module.scss';
import { ReactComponent as Logo } from './images/wsb_logo.svg';

interface menuProps {
    [k: string]: {
        href: string;
        title: string;
    }[];
}

const menuItems: menuProps = {
    '/': [
        {
            href: '#services',
            title: 'menu.services',
        },
        {
            href: '#capabilities',
            title: 'menu.capabilities',
        },
        {
            href: '#concept',
            title: 'menu.concept',
        },
        {
            href: '#clients',
            title: 'menu.clients',
        },
        {
            href: '#about',
            title: 'menu.about',
        },
    ],
    classic: [
        {
            href: '#types',
            title: 'ТИПЫ',
        },
        {
            href: '#structure',
            title: 'СТРУКТУРА',
        },
        {
            href: '#capabilities',
            title: 'ВОЗМОЖНОСТИ',
        },
        {
            href: '#disciplines',
            title: 'КЕЙСЫ',
        },
        {
            href: '#contacts',
            title: 'КОНТАКТЫ',
        },
    ],
    cyber: [
        {
            href: '#structure',
            title: 'СТРУКТУРА',
        },
        {
            href: '#format',
            title: 'ФОРМАТ',
        },
        {
            href: '#disciplines',
            title: 'КЕЙСЫ',
        },
        {
            href: '#capabilities',
            title: 'ВОЗМОЖНОСТИ',
        },
        {
            href: '#contacts',
            title: 'КОНТАКТЫ',
        },
    ],
    stream: [
        {
            href: '#streamTypes',
            title: 'Типы',
        },
        {
            href: '#equipment',
            title: 'ОБОРУДОВАНИЕ',
        },
        {
            href: '#cases',
            title: 'КЕЙСЫ',
        },
        {
            href: '#team',
            title: 'Студия',
        },
        {
            href: '#contacts',
            title: 'КОНТАКТЫ',
        },
    ],
};

export default function Header(props: { topOffset: number }) {
    const { topOffset } = props;
    let location = useLocation();
    const pathname = location.pathname;
    const type = pathname.split('/')[1] || '/';
    const subMenu = menuItems[`${type}`];
    const discipline = pathname.split('/')[2];
    const { language, setLanguage } = useContext(StateContext);
    const { t } = useTranslation();

    const sectionsMenu = [
        {
            href: '/classic',
            item: (
                <div className={classNames(s.sectionItem, s.classicItem)}>
                    <div className={s.firstText}>CLASSIC</div>
                    <div className={s.secondText}>sport</div>
                </div>
            ),
        },
        {
            href: '/cyber',
            item: (
                <div className={classNames(s.sectionItem, s.cyberItem)}>
                    <div className={s.firstText}>CYBER</div>
                    <div className={s.secondText}>sport</div>
                </div>
            ),
        },
        {
            href: '/stream',
            item: (
                <div className={classNames(s.sectionItem, s.streamItem)}>
                    <div className={s.firstText}>STREAM</div>
                    <div className={s.secondText}>STUDIO</div>
                </div>
            ),
        },
    ];
    return (
        <header
            className={classNames(
                s.menu,
                topOffset < 30 && !discipline ? s.menuTransparent : null,
                discipline ? s.menuStative : null,
            )}
        >
            <div className="wrapper">
                <div className={s.header}>
                    <NavLink to="/" className={s.logo}>
                        <Logo
                            className={classNames(s.logoSvg, type === '/' ? s.mainLogo : undefined)}
                        />
                    </NavLink>
                    <ul className={s.menuSections}>
                        {sectionsMenu.map((menuItem) => {
                            const isActive = menuItem.href === `/${type}`;
                            return (
                                <li
                                    className={classNames(
                                        s.sectionMenuItem,
                                        isActive && s.sectionMenuItemActive,
                                    )}
                                    key={menuItem.href}
                                >
                                    <NavLink to={`${menuItem.href}`}>{menuItem.item}</NavLink>
                                </li>
                            );
                        })}
                    </ul>
                    <ul className={s.menuList}>
                        {subMenu?.map((item) => (
                            <li className={s.subMenuItem} key={item.href}>
                                <a href={item.href} className={s.menuLink}>
                                    {t(item.title)}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div
                        className={s.language}
                        onClick={() => {
                            let nextLanguage: 'RU' | 'EN' = language === 'RU' ? 'EN' : 'RU';
                            console.log('current', language);
                            console.log('next', nextLanguage);
                            storeLanguageLocally(nextLanguage);
                            setLanguage(nextLanguage);
                        }}
                    >
                        {language === 'EN' ? 'RU' : 'EN'}
                    </div>
                </div>
            </div>
        </header>
    );
}
