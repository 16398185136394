import { CapabilitiesSlide } from '../../../Capabilities';
import slidePic2 from './images/highlight_cs.jpg';
import slidePic3 from './images/highlight_lol.jpg';
import slidePic4 from './images/hud_cs.jpg';
import slidePic5 from './images/hud_dota.jpg';
import slidePic1 from './images/org_tournament.jpg';
import slidePic6 from './images/stream_cs.jpg';
import slidePic7 from './images/stream_dota.jpg';
import slidePic8 from './images/stream_lol.jpg';

export const capabilitiesCyberItems: CapabilitiesSlide[] = [
    {
        title: 'Организация корпоративного турнира',
        img: slidePic1,
        youTubeCode: '7iRD-iDrtro',
    },
    {
        title: '[CS:GO] Что такое HUD?',
        img: slidePic4,
        youTubeCode: '-98KFAXI6Ic',
    },
    {
        title: '[DOTA 2] Что такое HUD?',
        img: slidePic5,
        youTubeCode: 'oIiEed7zcdM',
    },
    {
        title: '[CS:GO] Пример трансляции',
        img: slidePic6,
        youTubeCode: 'NEFyOMgLuso',
    },
    {
        title: '[DOTA 2] Пример трансляции',
        img: slidePic7,
        youTubeCode: 'zEOO-kHxALE',
    },
    {
        title: '[LoL] Пример трансляции',
        img: slidePic8,
        youTubeCode: 'We3jkbsgnyg',
    },
    {
        title: '[CS:GO] Пример отчетного ролика',
        img: slidePic2,
        youTubeCode: '-_z-eDEN3Wc',
    },
    {
        title: '[LoL] Пример отчетного ролика',
        img: slidePic3,
        youTubeCode: 'sHrqew3_l2Y',
    },

];
