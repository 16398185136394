import i18n from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { getLanguageLocally } from "../../helpers/lang";
import ScrollToTop from '../../helpers/ScrollToTop';
import getPageSeoData from "../../helpers/seo";
import ClassicContainer from '../ClassicContainer';
import CyberContainer from '../CyberContainer';
import Layout from '../Layout';
import MainContainer from '../MainContainer';
import PageNotFoundContainer from "../PageNotFound";
import StreamContainer from '../StreamContainer';

export const StateContext = React.createContext({} as MainState);
interface MainState {
    language: 'RU' | 'EN';
    setLanguage: Dispatch<SetStateAction<'RU' | 'EN'>>;
    isOpenedPopUp: boolean;
    setIsOpenedPopUp: Dispatch<SetStateAction<boolean>>;
    popUpComponent: null | JSX.Element;
    setPopUpComponent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}

function App() {
    const storedLanguage = getLanguageLocally();
    const [language, setLanguage] = useState<MainState['language']>(storedLanguage);
    const [isOpenedPopUp, setIsOpenedPopUp] = useState(false);
    const [popUpComponent, setPopUpComponent] = useState<null | JSX.Element>(null);

    // Set current user language
    i18n.changeLanguage(language);

    return (
        <StateContext.Provider
            value={{
                language,
                setLanguage,
                isOpenedPopUp,
                setIsOpenedPopUp,
                popUpComponent,
                setPopUpComponent,
            }}
        >
            <ScrollToTop />
            <Routes>
                <Route
                    path="/"
                    element={<Layout>{(props) => <MainContainer {...props} />}</Layout>}
                ></Route>
                <Route
                    path="/classic"
                    element={<Layout>{(props) => <ClassicContainer />}</Layout>}
                ></Route>
                <Route
                    path="/classic/:disciplines"
                    element={<Layout>{(props) => <ClassicContainer />}</Layout>}
                ></Route>
                <Route
                    path="/cyber"
                    element={<Layout>{(props) => <CyberContainer />}</Layout>}
                ></Route>
                <Route
                    path="/cyber/:disciplines"
                    element={<Layout>{(props) => <CyberContainer />}</Layout>}
                ></Route>
                <Route
                    path="/stream"
                    element={<Layout>{(props) => <StreamContainer />}</Layout>}
                ></Route>
                <Route
                    path="/stream/:disciplines"
                    element={<Layout>{(props) => <StreamContainer />}</Layout>}
                ></Route>
                <Route path="*" element={<Layout>{(props) => <PageNotFoundContainer />}</Layout>}></Route>
            </Routes>
        </StateContext.Provider>
    );
}

export default App;
