import classNames from 'classnames';

import CardsList, { Card } from '../CardsList';
import s from './Formats.module.scss';

export default function Formats(props: { cards: Card[] }) {
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <div className="anchor-place" id="formats"></div>
                <h2 className={classNames('section__title', s.reviews__title)}>
                    ФОРМАТЫ СПОРТИВНЫХ СОБЫТИЙ
                </h2>
                <CardsList cards={props.cards} />
            </div>
        </section>
    );
}
