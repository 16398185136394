import s from '../StreamTypes.module.scss';
import { ReactComponent as ClassicImg } from './images/Classic.svg';

export function Classic() {
    return (
        <div className={s.itemWrapper}>
            <div className={s.content}>
                <ul className={s.services}>
                    <li className={s.service}>
                        Профессиональные комментаторы по любым видам спорта
                    </li>
                    <li className={s.service}>Индивидуальное графическое оформление</li>
                    <li className={s.service}>Создание анимаций и стингеров</li>
                    <li className={s.service}>
                        Стриминг на любые платформы: YouTube, VK, Twitch и другие
                    </li>
                    <li className={s.service}>Трансляция в высоком качестве: 4k60fps</li>
                    <li className={s.service}>Повторы и хайлайты</li>
                    <li className={s.service}>Аналитика</li>
                    <li className={s.service}>Любое количество камер</li>
                    <li className={s.service}>Разноплановая съемка</li>
                    <li className={s.service}>Эксклюзирование контента</li>
                </ul>
                <div className={s.tags}>
                    <div className={s.tag}>ФУТБОЛ</div>
                    <div className={s.tag}>Волейбол</div>
                    <div className={s.tag}>Картинг</div>
                    <div className={s.tag}>Слалом</div>
                    <div className={s.tag}>и другие</div>
                </div>
            </div>
            <div className={s.pic}>
                <ClassicImg />
            </div>
        </div>
    );
}
