import classNames from "classnames";

import s from "./Clients.module.scss";
import ClientSlider from "./ClientsSlider";
import Reviews from "./Reviews";

export default function Clients() {
  return (
    <section className={classNames("section", s.reviews__section)}>
      <div className="wrapper">
        <div className="anchor-place" id="clients"></div>
        <h2 className={classNames("section__title", s.reviews__title)}>
          НАШИ КЛИЕНТЫ
        </h2>
        <ClientSlider />
        <Reviews />
      </div>
    </section>
  );
}
