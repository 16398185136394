import './i18n';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/normalize.scss';
import './styles/index.scss';
import './styles/theme.scss';
import './styles/slick-theme.scss';
import { BrowserRouter } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

import App from './components/App/App';

const tagManagerArgs = {
    id: 'GTM-5LBXKH8'
}
const yandexMetrikaOptions = {
    webvisor: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <YMInitializer accounts={[91699129]} options={yandexMetrikaOptions}/>
        <GTMProvider state={tagManagerArgs}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </GTMProvider>
    </React.StrictMode>,
);
