import classNames from 'classnames';

import s from './CyberTypes.module.scss';
import { ReactComponent as ClientsIcon } from './images/Clients.svg';
import { ReactComponent as CorpInnerIcon } from './images/CorpInner.svg';
import { ReactComponent as CorpOuterIcon } from './images/CorpOuter.svg';
import { ReactComponent as MarketingIcon } from './images/Marketing.svg';
import { ReactComponent as PartnersIcon } from './images/Partners.svg';
import { ReactComponent as SponsorsIcon } from './images/Sponsors.svg';

const cyberTypes = [
    { icon: <CorpInnerIcon />, title: 'ВНУТРИКОРПОРАТИВНЫЙ' },
    { icon: <CorpOuterIcon />, title: 'МЕЖКОРПОРАТИВНЫЙ' },
    { icon: <MarketingIcon />, title: 'МАРКЕТИНГОВЫЙ' },
    { icon: <SponsorsIcon />, title: 'СПОНСОРСКИЙ' },
    { icon: <PartnersIcon />, title: 'ПАРТНЕРСКИЙ' },
    { icon: <ClientsIcon />, title: 'КЛИЕНТСКИЙ' },
];

export function CyberTypes() {
    return (
        <section className={classNames('section', s.typesSection)}>
            <div className="wrapper">
                <div className="anchor-place" id="cyberTypes"></div>
                <h2 className={classNames('section__title', s.sectionTitle)}>
                    ТИПЫ СПОРТИВНЫХ СОБЫТИЙ
                </h2>
                <div className={s.typesList}>
                    {cyberTypes.map((item) => (
                        <div className={s.typesItem}>
                            <div className={s.typesPic}>{item.icon}</div>
                            <div className={s.typesTitle}>{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
