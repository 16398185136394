
import classNames from "classnames";
import { useState } from "react";

import MedalPic from "./images/medal.png";
import TranslationPic from "./images/translation.png";
import s from "./MainServices.module.scss";

export default function MainServices() {
  const cards = [
    { title: "ОРГАНИЗАЦИЯ спортивных мероприятий", pic: MedalPic, link: "https://formsports.worksportbalance.com/" },
    {
      title: "ОРГАНИЗАЦИЯ СПОРТИВНЫХ ТРАНСЛЯЦИЙ",
      pic: TranslationPic,
      link: "https://formsstudio.worksportbalance.com/",
    },
  ];
  return (
    <section className={classNames("section", s.mainServicesSection)}>
      <div className="wrapper">
        <div className="anchor-place" id="services"></div>
        <h2 className={classNames("section__title", s.mainServicesTitle)}>
          ОСНОВНЫЕ услуги
        </h2>
        <div className={s.services}>
          {cards.map((card) => (
            <Card card={card} />
          ))}
        </div>
      </div>
    </section>
  );
}

function Card(props: {
  card: {
    title: string;
    pic: string;
    link: string;
  };
}) {
  const { pic, title, link } = props.card;
  const [isHoverBtn, setHoverBtn] = useState(false);
  return (
    <div className={classNames(s.card, isHoverBtn && s.cardHovered)}>
      <div className={s.cardTitle}>{title}</div>
      <div className={s.cardContent}>
        <img src={pic} alt="" className={s.cardImg} />
      </div>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={s.cardBtn}
        onMouseEnter={() => setHoverBtn(true)}
        onMouseLeave={() => setHoverBtn(false)}
      >
        ЗАПОЛНИТЬ БРИФ И <br />РАССЧИТАТЬ СТОИМОСТЬ
      </a>
    </div>
  );
}
