import classNames from 'classnames';
import { useState } from 'react';
import Lottie from 'react-lottie';

import s from './ComplexApproach.module.scss';
import { slides } from './data';

export interface ComplexApproachSlide {
    title: string;
    key: number;
    animatedIcon: any;
    content: JSX.Element;
}

const animationDefaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export default function ComplexApproach() {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <section className={classNames('section', s.complexApproachSection)} id="structure">
            <div className="wrapper">
                <div className="anchor-place" id="structure"></div>
                <h2 className={classNames('section__title', s.sectionTitle)}>КОМПЛЕКСНЫЙ ПОДХОД</h2>
                <div className={s.taber}>
                    <div className={s.tabsList}>
                        <div className={s.tabsListWrapper}>
                            {slides.map((slideItem) => (
                                <div
                                    className={classNames(
                                        s.tab,
                                        activeTab === slideItem.key && s.activeTab,
                                    )}
                                    onClick={() => {
                                        setActiveTab(slideItem.key);
                                    }}
                                >
                                    <div className={s.tabTitle}>{slideItem.title}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={s.tabContentWrapper}>
                        {slides.map((slideItem) => (
                            <div
                                className={classNames(
                                    s.tabContent,
                                    activeTab === slideItem.key && s.tabContentVisible,
                                )}
                            >
                                <div className={s.content}>
                                    <div>{slideItem.content}</div>
                                    <Lottie
                                        options={{
                                            ...animationDefaultOptions,
                                            animationData: slideItem.animatedIcon,
                                        }}
                                        height={250}
                                        width={250}
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
