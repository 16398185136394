import TeamImg from '../../sharedImages/main.jpg';
import GallerySlider from '../GallerySlider';
import Img1 from './images/1.png';
import TeamImg1 from './images/qwe1.png';
import TeamImg2 from './images/qwe2.png';
import TeamImg3 from './images/qwe3.png';
import TeamImg4 from './images/qwe4.png';
import TeamImg5 from './images/qwe5.png';
import TeamImg6 from './images/qwe6.png';

const cards = [TeamImg1, TeamImg2, TeamImg3, TeamImg4, TeamImg5, TeamImg6, Img1, TeamImg];

export default function TeamGallery() {
    return <GallerySlider slides={cards} title="мы в деле" />;
}
