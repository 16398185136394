import classNames from "classnames";

import s from "./PageNotFound.module.scss";

export default function PageNotFoundContainer() {

    return (
        <>
            <section className={classNames("section", s.page404)}>
                <div className={s.page404Filter}></div>
                <div className={classNames("wrapper", s.page404Wrapper)}>
                    <div className={s.page404Text}>
                        <div className={s.page404Title}>
                            Oops :(
                        </div>
                        <div className={s.page404Subtitle}>
                            Страница не найдена
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}
