import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import SwiperType, { Navigation, Thumbs, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';

import s from './Capabilities.module.scss';
import { ReactComponent as Arrow } from './images/Arrow.svg';
import { ReactComponent as YouTubeBtn } from './images/youtube_btn.svg';

export interface CapabilitiesSlide {
    title: string;
    img: string;
    youTubeCode: string;
}

export function Capabilities(props: { slides: CapabilitiesSlide[] }) {
    const { slides } = props;
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
    const swiperRef = useRef<SwiperCore>();

    return (
        <section className={classNames('section', s.capabilitiesSection)}>
            <div className="wrapper">
                <div className="anchor-place" id="capabilities"></div>
                <h2 className={classNames('section__title', s.capabilitiesTitle)}>
                    НАШИ ВОЗМОЖНОСТИ
                </h2>
                {/* // Navigation doesn't work if change Swiper components Order */}
                <div className={s.sliderWrapper}>
                    <div className={s.thumbsWrapper}>
                        <div
                            className={s.slider__prev}
                            onClick={() => swiperRef.current?.slidePrev()}
                        >
                            <Arrow />
                        </div>

                        <div className={s.slider__thumbs}>
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                direction="vertical"
                                spaceBetween={14}
                                slidesPerView={3}
                                loop
                                thumbs={{
                                    swiper:
                                        thumbsSwiper && !thumbsSwiper.destroyed
                                            ? thumbsSwiper
                                            : null,
                                }}
                                slideToClickedSlide
                                centeredSlides
                                className={s['swiper-container1']}
                                modules={[Navigation, Thumbs]}
                                onBeforeInit={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                            >
                                {slides.map((slide, index) => {
                                    return (
                                        <SwiperSlide key={index} className={s.thumbSlide}>
                                            <div className={s.thumbImg}>
                                                <img src={slide.img} alt="" />
                                            </div>
                                            <div className={s.thumbTitle}>{slide.title}</div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>

                        <div
                            className={s.slider__next}
                            onClick={() => swiperRef.current?.slideNext()}
                        >
                            <Arrow className={s.rotatedArrow} />
                        </div>
                    </div>
                    <div className={s.mainSlider}>
                        <Swiper
                            effect={'fade'}
                            loop
                            onSwiper={setThumbsSwiper}
                            direction="vertical"
                            slidesPerView={'auto'}
                            spaceBetween={32}
                            slideToClickedSlide
                            className={s['swiper-container2']}
                            allowTouchMove={false}
                            modules={[Navigation, Thumbs, EffectFade]}
                            watchSlidesProgress
                        >
                            {slides.map((slide, index) => {
                                return (
                                    <SwiperSlide key={index} className={s.mainSlide}>
                                        {({ isActive }) => (
                                            <MainSlide slide={slide} isActive={isActive} />
                                        )}
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

function MainSlide(props: { slide: CapabilitiesSlide; isActive: boolean }) {
    const { slide, isActive } = props;

    const [isPlayed, setIsPlayed] = useState(false);

    useEffect(() => {
        setIsPlayed(false);
    }, [isActive]);

    return (
        <>
            {isPlayed ? (
                <>
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${slide.youTubeCode}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className={s.iframe}
                        style={{ backgroundImage: `url(${slide.img})` }}
                    ></iframe>
                    <div className={s.mainSlideTitle}>{slide.title}</div>
                </>
            ) : (
                <>
                    <div className={s.mainSlidePic}>
                        <img src={slide.img} alt="" />
                        <div className={s.customPlayButton}>
                            <YouTubeBtn
                                className={s.youTubeBtn}
                                onClick={() => {
                                    setIsPlayed(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.mainSlideTitle}>{slide.title}</div>
                </>
            )}
        </>
    );
}
