
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import s from "./Projects.module.scss";

export default function Projects() {
  const cards = [
    {
      href: "/classic",
      id: "classicBlock",
      item: (
        <div className={classNames(s.sectionItem, s.classicItem)}>
          <div className={s.firstText}>CLASSIC</div>
          <div className={s.secondText}>sport</div>
        </div>
      ),
    },
    {
      href: "/cyber",
      id: "cyberBlock",
      item: (
        <div className={classNames(s.sectionItem, s.cyberItem)}>
          <div className={s.firstText}>CYBER</div>
          <div className={s.secondText}>sport</div>
        </div>
      ),
    },
    {
      href: "/stream",
      id: "streamBlock",
      item: (
        <div className={classNames(s.sectionItem, s.streamItem)}>
          <div className={s.firstText}>STREAM</div>
          <div className={s.secondText}>STUDIO</div>
        </div>
      ),
    },
  ];
  return (
    <section className={classNames("section", s.projectsSection)}>
      <div className="wrapper">
        <div className="anchor-place" id="projects"></div>
        <h2 className={classNames("section__title", s.projectsTitle)}>
          ПРОЕКТЫ
        </h2>
        <ul className={s.projectList}>
          {cards.map((menuItem) => (
            <li
              className={classNames(s.projectItem, s[`${menuItem.id}`])}
              key={menuItem.href}
            >
              <div className={s.itemWrapper}>
                <NavLink to={`${menuItem.href}`} className={s.itemLink}>
                  {menuItem.item}
                </NavLink>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
