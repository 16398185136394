import Slider from "react-slick";


import { SliderArrow } from "../Reviews";
import s from "./ClientSlider.module.scss";
import ALTENARLogo from "./images/ALTENAR_white+color.svg";
import aquivalabsLogo from "./images/aquivalabs_white+color.svg";
import arcadiaLogo from "./images/arcadia_logo_color.svg";
import ArgusLogo from "./images/Argus_logo_white+color.svg";
import astraLogo from "./images/astra_linux_white+color.svg";
import AurigaLogo from "./images/Auriga_logo.svg";
import AzurGamesLogo from "./images/Azur Games_logo.svg";
import BegetLogo from "./images/beget_white_bg.svg";
import CindicLogo from "./images/CINDICATOR_logo.svg";
import CinimexLogo from "./images/Cinimex_white+color.svg";
import DataartLogo from "./images/Dataart.svg";
import deeplayLogo from "./images/deeplay_color.svg";
import DellLogo from "./images/DELL_logo.svg";
import DevexpertsLogo from "./images/Devexperts_logo.svg";
import Digital_DesignLogo from "./images/Digital_Design_white.svg";
import DinsLogo from "./images/Dins_color.svg";
import DTtelecomLogo from "./images/DTtelecom _color.svg";
import ecommpayLogo from "./images/ecommpay_white+color.svg";
import EffectiveTechnologiesLogo from "./images/Effective Technologies_logo_color.svg";
import EPAMLogo from "./images/EPAM_logo_white+color.svg";
import EXCORPLogo from "./images/EXCORP_logo_color-01.svg";
import F5Logo from "./images/F5_logo_black_full-01.svg";
import FirstlineLogo from "./images/Firstline_logo.svg";
import GridDynamicsLogo from "./images/Grid-Dynamics_logo.svg";
import gs_labsLogo from "./images/gs_labs_white+color.svg";
import HitechLogo from "./images/Hitech_white+color.svg";
import InfobipLogo from "./images/infobip.svg";
import IntermediaLogo from "./images/intermedia.svg";
import IntraLogo from "./images/Intra.svg";
import IqLogo from "./images/iq_option.svg";
import IT2GLogo from "./images/IT2G_logo.svg";
import IxcoLogo from "./images/iXco.svg";
import JetbrainsLogo from "./images/Jetbrains.svg";
import karunaLogo from "./images/karuna_white+color.svg";
import LamodaLogo from "./images/lamoda-01.svg";
import LightmapLogo from "./images/Lightmap_logo.svg";
import LineateLogo from "./images/Lineate.svg";
import ModulLogo from "./images/Modulbank.svg";
import MoiOffLogo from "./images/moi_ofis.svg";
import MtsLogo from "./images/mts_logo.svg";
import napopravkuLogo from "./images/napopravku_white.svg";
import NetcrackerLogo from "./images/Netcracker.svg";
import NetwrixLogo from "./images/Netwrix.svg";
import OkkoLogo from "./images/Okko-Logo.svg";
import OrionLogo from "./images/Orion_logo.svg";
import PlaykotLogo from "./images/playkot-logo_2-01.svg";
import PropellerAdsLogo from "./images/PropellerAds_logo.svg";
import QuantoriLogo from "./images/Quantori_logo.svg";
import quantoriLogo from "./images/quantori_white+color.svg";
import RabotaLogo from "./images/RabotaRu_Logo_RGB_Blue_Grafit.svg";
import RJGamesLogo from "./images/RJ-Games_logo.svg";
import SaberLogo from "./images/saber.svg";
import SberAutotechLogo from "./images/SberAutotech_logo.svg";
import SelectelLogo from "./images/Selectel_white+color.svg";
import semrushLogo from "./images/semrush_white+color.svg";
import SeverstalLogo from "./images/Severstal_white+color.svg";
import simbirsoftLogo from "./images/simbirsoft_color.svg";
import SmartcatLogo from "./images/Smartcat-logo_color.svg";
import Space307Logo from "./images/Space307_logo.svg";
import SRGITLogo from "./images/SRG-IT_logo.svg";
import sumsubLogo from "./images/sumsub_white+color.svg";
import TraceStudioLogo from "./images/TraceStudio_logo_white+color.svg";
import VeeamLogo from "./images/Veeam.svg";
import WrikeLogo from "./images/Wrike_logo.svg";
import XoredLogo from "./images/Xored_logo.svg";
import YadroLogo from "./images/Yadro.svg";
import ZodiakLogo from "./images/Zodiac.svg";
import ZyfraLogo from "./images/Zyfra_logo.svg";
import ProteiLogo from "./images/протей.svg";
import цвтLogo from "./images/цвт-logo_white+color.svg";
import ЦРТLogo from "./images/ЦРТ_white+color.svg";
import YMoneyLogo from "./images/Ю-money_logo.svg";

const clientsList = [
  {
    img: OkkoLogo,
    title: "Okko",
  },
  {
    img: MtsLogo,
    title: "Mts",
  },
  {
    img: SberAutotechLogo,
    title: "SberAutotech",
  },
  {
    img: YMoneyLogo,
    title: "Ю-money",
  },
  {
    img: JetbrainsLogo,
    title: "Jetbrains",
  },
  {
    img: LamodaLogo,
    title: "Lamoda",
  },
  {
    img: DataartLogo,
    title: "Dataart",
  },
  {
    img: CindicLogo,
    title: "Cindic",
  },
  {
    img: IntermediaLogo,
    title: "Intermedia",
  },
  {
    img: F5Logo,
    title: "F5",
  },
  {
    img: InfobipLogo,
    title: "Infobip",
  },
  {
    img: IxcoLogo,
    title: "Ixco",
  },
  {
    img: IqLogo,
    title: "Iq",
  },
  {
    img: MoiOffLogo,
    title: "MoiOff",
  },
  {
    img: NetcrackerLogo,
    title: "Netcracker",
  },
  {
    img: NetwrixLogo,
    title: "Netwrix",
  },
  {
    img: RabotaLogo,
    title: "Rabota",
  },
  {
    img: PlaykotLogo,
    title: "Playkot",
  },
  {
    img: SaberLogo,
    title: "Saber",
  },
  {
    img: ZodiakLogo,
    title: "Zodiak",
  },
  {
    img: YadroLogo,
    title: "Yadro",
  },
  {
    img: BegetLogo,
    title: "Beget",
  },
  {
    img: ArgusLogo,
    title: "Argus",
  },
  {
    img: ProteiLogo,
    title: "Протей",
  },
  {
    img: Digital_DesignLogo,
    title: "Digital_Design",
  },
  {
    img: DinsLogo,
    title: "Dins",
  },
  {
    img: ecommpayLogo,
    title: "ecommpay",
  },
  {
    img: sumsubLogo,
    title: "sumsub",
  },
  {
    img: simbirsoftLogo,
    title: "simbirsoft",
  },
  {
    img: napopravkuLogo,
    title: "napopravku",
  },
  {
    img: EPAMLogo,
    title: "EPAM",
  },
  {
    img: astraLogo,
    title: "astra",
  },
  {
    img: SelectelLogo,
    title: "Selectel",
  },
  {
    img: HitechLogo,
    title: "Hitech",
  },
  {
    img: CinimexLogo,
    title: "Cinimex",
  },
  {
    img: AurigaLogo,
    title: "Auriga",
  },
  {
    img: AzurGamesLogo,
    title: "Azur Games",
  },
  {
    img: DellLogo,
    title: "DELL",
  },
  {
    img: DevexpertsLogo,
    title: "Devexperts",
  },
  {
    img: FirstlineLogo,
    title: "Firstline",
  },
  {
    img: GridDynamicsLogo,
    title: "Grid-Dynamics",
  },
  {
    img: IntraLogo,
    title: "Intra",
  },
  {
    img: IT2GLogo,
    title: "IT2G",
  },
  {
    img: LightmapLogo,
    title: "Lightmap",
  },
  {
    img: ModulLogo,
    title: "Modul",
  },
  {
    img: OrionLogo,
    title: "Orion",
  },
  {
    img: PropellerAdsLogo,
    title: "PropellerAds",
  },
  {
    img: QuantoriLogo,
    title: "Quantori",
  },
  {
    img: RJGamesLogo,
    title: "RJGames",
  },
  {
    img: Space307Logo,
    title: "Space307",
  },
  {
    img: SRGITLogo,
    title: "SRGIT",
  },
  {
    img: VeeamLogo,
    title: "SRG-IT",
  },
  {
    img: WrikeLogo,
    title: "Veeam",
  },
  {
    img: XoredLogo,
    title: "Wrike",
  },
  {
    img: ZyfraLogo,
    title: "Xored",
  },
  {
    img: ALTENARLogo,
    title: "ALTENAR",
  },
  {
    img: aquivalabsLogo,
    title: "aquivalabs",
  },
  {
    img: deeplayLogo,
    title: "deeplay",
  },
  {
    img: semrushLogo,
    title: "semrush",
  },
  {
    img: karunaLogo,
    title: "karuna",
  },
  {
    img: arcadiaLogo,
    title: "arcadia",
  },
  {
    img: DTtelecomLogo,
    title: "DTtelecom",
  },
  {
    img: SmartcatLogo,
    title: "Smartcat",
  },
  {
    img: EXCORPLogo,
    title: "EXCORP",
  },
  {
    img: quantoriLogo,
    title: "quantori",
  },
  {
    img: LineateLogo,
    title: "Lineate",
  },
  {
    img: ЦРТLogo,
    title: "ЦРТ",
  },
  {
    img: EffectiveTechnologiesLogo,
    title: "EffectiveTechnologies",
  },
  {
    img: TraceStudioLogo,
    title: "TraceStudio",
  },
  {
    img: SeverstalLogo,
    title: "Severstal",
  },
  {
    img: gs_labsLogo,
    title: "gs_labs",
  },
  {
    img: цвтLogo,
    title: "цвт",
  },
];

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 6,
  prevArrow: <SliderArrow to="prev" />,
  nextArrow: <SliderArrow to="next" />,
};

export default function ClientSlider() {
  return (
    <Slider {...settings} className={s.clients__list}>
      {clientsList.map((client) => {
        return (
          <li className={s.slider__item}>
            <img
              src={client.img}
              alt={`${client.title}_logo`}
              title={client.title}
              className={s.sponsor__logo}
            ></img>
          </li>
        );
      })}
    </Slider>
  );
}
