import { useParams } from 'react-router-dom';

import Approach from '../Approach';
import { Capabilities } from '../Capabilities';
import Contacts from '../Contacts';
import DisciplinePage from '../DisciplinePage';
import DisciplinesClassic from '../DisciplinesClassic';
import Footer from '../Footer';
import Formats from '../Formats';
import Hero, { HeroDataProps } from '../Hero';
import SubscribeForm from '../SubscribeForm';
import Types from '../Types';
import { classicDisciplinesCards, formatCards, typeCards, approachItems } from './data';
import { capabilitiesClassicItems } from './data/CapabilitiesData';
import videoBg from './images/classic_.mp4';
import heroPic from './images/classic_stream.jpg';

export default function ClassicContainer() {
    let { disciplines } = useParams();

    const card = classicDisciplinesCards.find((card) => {
        return card.id === disciplines;
    });

    return (
        <>
            {card ? (
                <DisciplinePage
                    mainPath="classic"
                    card={card}
                    cyberDisciplinesCards={classicDisciplinesCards}
                />
            ) : (
                <>
                    <Hero {...heroData} />
                    <Formats cards={formatCards} />
                    <Types cards={typeCards} />
                    <Approach listItems={approachItems} />
                    <Capabilities slides={capabilitiesClassicItems} />
                    <DisciplinesClassic cards={classicDisciplinesCards} />
                    <SubscribeForm />
                    <Contacts />
                    <Footer />
                </>
            )}
        </>
    );
}

const heroData: HeroDataProps = {
    bg: heroPic,
    video: videoBg,
    youTubeCode: 'uXkgs-aB9o0',
    id: 'classic',
    title: 'classic',
    popUpVideoTitle: 'Спортивный 2019 год Кубка IT-Challenge',
    sectionTitle: 'ОРГАНИЗАТОР КОРПОРАТИВНЫХ СОРЕВНОВАНИЙ',
    buttonAction: 'link',
    productLink: 'https://formsports.worksportbalance.com/',
};
