import classNames from 'classnames';

import { ReactComponent as EyeIcon } from './images/eye.svg';
import { ReactComponent as HealthyIcon } from './images/Healthy.svg';
import { ReactComponent as LikeIcon } from './images/like.svg';
import { ReactComponent as LoyalityIcon } from './images/loyality.svg';
import { ReactComponent as QuoteIcon } from './images/quote.svg';
import { ReactComponent as SocialmediaIcon } from './images/social-media.svg';
import { ReactComponent as SportimageIcon } from './images/sport-image.svg';
import { ReactComponent as TeamworkIcon } from './images/teamwork.svg';
import s from './Values.module.scss';

const content = [
    {
        title: 'Даём возможность познакомиться поближе и пообщаться с коллегами в неформальной обстановке, чтобы найти общие интересы вне работы',
        icon: <EyeIcon />,
    },
    {
        title: 'Влюбляем в спорт. Спортивные сотрудники - более волевые и целеустремленные, эффективнее работают и реже берут больничные',
        icon: <TeamworkIcon />,
    },
    {
        title: 'Создаём позитивный инфоповод для публикации во внутрикорпоративных, внешних медиаресурсах, в СМИ и социальных сетях',
        icon: <SocialmediaIcon />,
    },
    {
        title: 'ЗОЖ - это тренд. Помогаем включиться в активный отдых, учим получать кайф от физической активности и командной игры',
        icon: <HealthyIcon />,
    },
    {
        title: 'Помогаем создать и укрепить доверительные отношения между топ-менеджментом и линейными сотрудниками, сплотить команды внутри отделов',
        icon: <LikeIcon />,
    },
    {
        title: 'Корпоративный спорт - это бенефит, который поможет удержать в компании высококлассных специалистов',
        icon: <LoyalityIcon />,
    },
    {
        title: 'Спортивный вайб улучшает ваш HR-бренд, показывает, что вы заботитесь о здоровье и досуге ваших сотрудников',
        icon: <SportimageIcon />,
    },
    {
        title: '“Легко уйти из компании, а из любимой команды - практически невозможно! Превратим ваш коллектив в настоящую dreamteam!”',
        icon: <QuoteIcon />,
        accent: true,
    },
];

export default function Values() {
    return (
        <section className={classNames('section', s.sectionValues)}>
            <div className="wrapper">
                <div className="anchor-place" id="concept"></div>
                <h2 className="section__title">
                    Концепция <span className="section__title--white"></span>
                </h2>
                <div className={s.cards}>
                    {content.map((card) => (
                        <div className={s.card}>
                            <div className={s.icon}>{card.icon}</div>
                            <div className={classNames(s.text, card.accent && s.textAccent)}>
                                {card.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
