import classNames from 'classnames';
import { useState } from 'react';

import s from './CardsList.module.scss';

export interface Card {
    front: string;
    back: string;
}

export default function CardsList(props: { cards: Card[] }) {
    const { cards } = props;

    return (
        <div className={s.cardsList}>
            {cards.map((cardItem) => (
                <Card card={cardItem} />
            ))}
        </div>
    );
}

function Card(props: { card: Card }) {
    const { card } = props;
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className={classNames(s.card, isHover && s.cardHovered)}
            key={card.front}
            onMouseEnter={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
        >
            <div className={s.front}>
                <div className={s.title}>{card.front}</div>
                <div className={s.question}>?</div>
            </div>
            <div className={s.back}>
                <div className={s.title}>{card.back}</div>
            </div>
        </div>
    );
}
