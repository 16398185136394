export interface PageParams {
    title?: string | null;
    description?: string | null;
    keywords?: string | null;
}

export const PagesSeoData: { [name: string]: PageParams } = {
    "/": {
        "title": "WORK SPORT BALANCE",
        "description": "Организатор корпоративного спорта. Успешно проводим турниры в онлайне и оффлайне, и по классическим видам спорта, и по киберспортивным дисциплинам.",
        "keywords": "Организаторы, корпоративного, спорта, мероприятий, Санкт-Петербурге, Москве, киберспортивных, турниров, Онлайн, трансляция, Work Sport Balance, спартакиады, олимпиады, WSB",
    },
    "/classic": {
        "title": "CLASSIC SPORT - Work Sport Balance",
        "description": "Проводем турниры по классическим видам спорта: футбол, волейбол, картинг, слалом, кикер, баскетбол, боулинг.",
        "keywords": "Корпоративное, первенство, Соревнование, Организаторы корпоративного спорта, Корпоративный спорт, мероприятия, Санкт-Петербурге, Москва"
    },
    "/cyber": {
        "title": "CYBER SPORT - Work Sport Balance",
        "description": "Проводем турниры по любым видам киберспортивных дисциплин: CS:GO, Dota 2, LoL, FIFA, MK, Valorant, Assetto Corsa",
        "keywords": "CS:GO, Dota 2, LoL, FIFA, MK, Valorant, Assetto Corsa, Киберсорт, турниров, Counter Strike, Online, Онлайн, League of Legends, LAN"
    },
    "/stream": {
        "title": "STREAM STUDIO - Work Sport Balance",
        "description": "Организуем и проведем онлайн трансляцию конференций, митапов, вебинаров, спортивный событий, подкастов",
        "keywords": "Студия, Стрим, Трансляция"
    },
    "/classic/pingpong": {
        "title": "НАСТОЛЬНЫЙ ТЕННИС - Work Sport Balance",
        "description": "Проведение и организация турниров по настольному теннису",
        "keywords": "Ping-pong, настольный теннис, пинг-понг"
    },
    "/classic/chess": {
        "title": "ШАХМАТЫ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по шахматам",
        "keywords": "Шахматы"
    },
    "/classic/quiz": {
        "title": "КВИЗ - Work Sport Balance",
        "description": "Организуем и проведем квиз",
        "keywords": "Квиз, Quiz"
    },
    "/classic/carting": {
        "title": "КАРТИНГ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по картингу",
        "keywords": "Картинг"
    },
    "/classic/bowling": {
        "title": "БОУЛИНГ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по боулингу",
        "keywords": "Боулинг"
    },
    "/classic/kicker": {
        "title": "КИКЕР - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по кикеру",
        "keywords": "Кикер"
    },
    "/classic/bike": {
        "title": "ВЕЛО - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по велоспорту",
        "keywords": "Вело, Вело-Кросс-Кантри"
    },
    "/classic/football": {
        "title": "ФУТБОЛ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по футболу",
        "keywords": "Футбол"
    },
    "/classic/volleyball": {
        "title": "ВОЛЕЙБОЛ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по волейболу",
        "keywords": "Волейбол"
    },
    "/classic/run": {
        "title": "БЕГ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по бегу",
        "keywords": "Бег, забег"
    },
    "/classic/sailing": {
        "title": "РЕГАТА - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по парусной регате",
        "keywords": "Парусная, регата, яхта"
    },
    "/classic/paintball": {
        "title": "ПЕЙНТБОЛ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по пейнтболу",
        "keywords": "Пейнтбол"
    },
    "/classic/ski": {
        "title": "СЛАЛОМ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по слалому и горнолыжному спуску",
        "keywords": "Слалом, лыжи, спуск, сноуборд"
    },
    "/classic/basket": {
        "title": "БАСКЕТБОЛ - Work Sport Balance",
        "description": "Организуем и проведем чемпионат по баскетболу",
        "keywords": "Баскетбол"
    },
    "/cyber/counter_strike": {
        "title": "COUNTER STRIKE - Work Sport Balance",
        "description": "Организуем и проведем турнир по Counter Strike",
        "keywords": "Counter-Strike, CS:GO,  Контр-Страйк, Global, Offensive"
    },
    "/cyber/dota2": {
        "title": "DOTA 2 - Work Sport Balance",
        "description": "Организуем и проведем турнир по DOTA 2",
        "keywords": "DOTA 2, Online"
    },
    "/cyber/league_of_legends": {
        "title": "LEAGUE OF LEGENDS - Work Sport Balance",
        "description": "Организуем и проведем турнир по League of Legends",
        "keywords": "League of legends, LoL"
    },
    "/cyber/fifa": {
        "title": "FIFA - Work Sport Balance",
        "description": "Организуем и проведем турнир по FIFA",
        "keywords": "fifa"
    },
    "/cyber/mortal_kombat": {
        "title": "MORTAL KOMBAT - Work Sport Balance",
        "description": "Организуем и проведем турнир по Mortal Kombat",
        "keywords": "mortal kombat, MK"
    },
    "/cyber/overwatch": {
        "title": "OVERWATCH 2 - Work Sport Balance",
        "description": "Организуем и проведем турнир по Overwatch",
        "keywords": "overwatch, overwatch 2"
    },
    "/cyber/quake": {
        "title": "QUAKE - Work Sport Balance",
        "description": "Организуем и проведем турнир по Quake",
        "keywords": "Quake"
    },
};

export function getPageSeoData(path: string) : PageParams {
    return PagesSeoData[path] || {};
}

export default getPageSeoData;