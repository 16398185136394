import classNames from 'classnames';

import CardsList, { Card } from '../CardsList';
import s from './Types.module.scss';

export default function Types(props: { cards: Card[]; title?: string }) {
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <div className="anchor-place" id="types"></div>
                <h2 className={classNames('section__title', s.reviews__title)}>
                    {props.title ? props.title : 'ТИПЫ СПОРТИВНЫХ СОБЫТИЙ'}
                </h2>
                <CardsList cards={props.cards} />
            </div>
        </section>
    );
}
