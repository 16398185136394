import { useContext } from 'react';

import { StateContext } from '../App/App';
import s from './Popup.module.scss';

export default function Popup() {
    const { popUpComponent, setPopUpComponent, setIsOpenedPopUp } = useContext(StateContext);
    return (
        <div
            className={s.popupWrapper}
            onClick={() => {
                setPopUpComponent(null);
                setIsOpenedPopUp(false);
            }}
        >
            {popUpComponent}
        </div>
    );
}
