import "slick-carousel/slick/slick.css";
import classNames from "classnames";
import Slider from "react-slick";

import s from "../Clients.module.scss";
import { ReactComponent as LeftArrow } from "../images/left_arrow_grey.svg";
import { ReactComponent as RightArrow } from "../images/right_arrow_grey.svg";
import { ReactComponent as CyberLogo } from "./icon/CYBER_ICON_.svg";
import { ReactComponent as ITCLogo } from "./icon/ITC.svg";
import AShBg from "./images/Alesya Shakhova.png";
import ASvBg from "./images/Anastasia Svetlichnaya.png";
import DRBg from "./images/Daria Ryabova.png";
import EDBg from "./images/Ekaterina Drozdetskaya.png";
import EDm from "./images/Ekaterina-Dmitrieva.png";
import ESBg from "./images/Elena_Isakova.png";
import JGBg from "./images/Julia_Gromceva.png";
import MKBg from "./images/Mariana Khrabrova.png";
import MPBg from "./images/Marina Popovskaya.png";
import NBBg from "./images/Natalya_Bakhmurnova.png";
import ZVBg from "./images/Zinaida Volkova.png";

const reviews = [
  {
    confImg: <CyberLogo />,
    reviewText:
      "Пришли к Денису и команде IT-Events без особых ожиданий, но в итоге получилось бомбическое мероприятие. Очень качественная организация от работы с командами до трансляций игр. Турнир наделал много шума внутри компании: собрались 6 команд, проводили тренировки, появилась каста болельщиков, оживили группу киберспорта. Рекомендую🔥",
    avatar: AShBg,
    name: "Алеся Шахова",
    company: "Модульбанк",
    position: "Руководитель центра по управлению персоналом",
    reviewTextEng:
      "I'm grateful to the HR API team for the brilliant organization, this is the most convenient conference in my experience, where I participated as a speaker. The guys are very cool!:) This  year's program was indeed one of the most intense. I am very glad that the guys managed to transfer the event to online with such success!",
    nameEng: "Alesya Shakhova",
    companyEng: "Модульбанк",
    positionEng: "Руководитель центра по управлению персоналом",
    year: 2022,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Понравилось, с каким ответственным подходом ребята организовывают турниры. Заранее высылают подробный брифинг, отвечают на все сопутствующие вопросы. Наше мероприятие было организовано четко и профессионально, без как-либо накладок. Отдельно хочется выделить работу комментатора, которая подогревала особый интерес к игре.",
    avatar: ZVBg,
    name: "Зинаида Волкова",
    company: "RJ Games",
    position: "Lead HR manager",
    reviewTextEng:
      'I deal with the guys from IT-Events since 2014 when I first attended the HR API conference (ex. "NayTie otvet"). Back there, I appreciated how responsible they are for their work, for the participants, for the speakers, and how much attention and love they put into what they do.',
    nameEng: "Zinaida Volkova",
    year: 2022,
  },
  {
    confImg: <ITCLogo />,
    reviewText:
      "Работать с ребятами из IT-Events приятно: им удалось создать вокруг себя соревновательную, но дружескую атмосферу, в которой увлеченные люди получают удовольствие от своих хобби. Турниры постоянно совершенствуются, добавляются новые виды спорта, людей собирается все больше - это здорово!",
    avatar: ASvBg,
    name: "Анастасия Светличная",
    company: "Selectel",
    position: "Руководитель отдела персонала",
    reviewTextEng:
      "It is a pleasure to work with the guys from IT-Events: they managed to create a competitive but friendly atmosphere where enthusiastic people enjoy their hobbies. Tournaments are constantly being improved, new sports are being added, and more people are gathering - this is great!",
    nameEng: "Anastasia Svetlichnaya",
    positionEng: "Head of HR Department",
    year: 2021,
  },
  {
    confImg: <ITCLogo />,
    reviewText:
      "SEMrush участвует в спортивных турнирах IT-Events уже много лет, и мы всегда очень довольны тем, как все проходит! Ребята заранее информируют о предстоящих турнирах, их правилах и регламентах, в день проведения турниров все проходит гладко и без накладок. Даже если мы не выигрываем, мы всегда уходим с хорошим настроением, потому что участие в турнире - это всегда весело, интересно и драйвово!",
    avatar: NBBg,
    name: "Наталья Бахмурнова",
    company: "SEMrush",
    position: "HR Business Partner",
    reviewTextEng:
      "SEMrush has been participating in IT-Events for many years, and we are always very happy with how everything goes! Guys inform in advance about upcoming tournaments, rules and regulations, at the day of the tournament everything runs smoothly and without unexpected occurrences. Even if we don't win, we always leave in a good mood, because participation in the tournament is always interesting, fun and driving!",
    nameEng: "Natalya Bakhmurnova",
    positionEng: "HR Business Partner",
    year: 2021,
  },
  {
    confImg: <ITCLogo />,
    reviewText:
      "Команды DataArt участвуют в спортивных событиях IT-Events много лет и каждый раз — с большим удовольствием. Вы создаете не просто турниры, а встречи близких по духу людей. С уверенностью скажу: IT-Events для нас значит больше, чем просто организатор турниров. IT-Events помогает строить и поддерживать наши спортивные сообщества, в основе которых лежат крепкие и душевные связи.",
    avatar: JGBg,
    name: "Юлия Громцева",
    company: "DataArt",
    position: "PR-менеджер",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Julia Gromtseva",
    positionEng: "PR-manager",
    year: 2021,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Огромная благодарность ребятам из IT-Events за организацию турнира по CS:GO на 23 февраля. Сделали все в кратчайшие сроки. Помимо игры была организована прямая трансляция, а благодаря огненным комментаторам интересно было не только самим игрокам, но и другим сотрудникам и сочувствующим.",
    avatar: DRBg,
    name: "Дарья Рябова",
    company: "SRG IT",
    position: "Руководитель отдела персонала",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Daria Ryabovaa",
    positionEng: "Руководитель отдела персонала",
    year: 2022,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Уже очень давно работаем с ребятами IT-Events и доверяем им все наши кибертурниры. Очень круто, что с каждым разом команда развивается, генерит новые фичи, подстраивается под любые условия и наши турниры становятся все ярче и интересней. Ответственно подходят к каждому событию - абсолютно спокойно можно доверить им всю организацию и просто отдыхать на своем ивенте!",
    avatar: MPBg,
    name: "Марина Поповская",
    company: "Azur Games",
    position: "HR Manager",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Марина Поповская",
    positionEng: "HR Manager",
    year: 2022,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Качество трансляции было на высоте, очень понравилась работа кастера и обсервера! Смогли доступно объяснить зрителям, что происходит в игре. Ребятам удалось почувствовать себя профессиональными игроками. Один из участников, после турнира, привёл инженера в нашу команду. Наличие таких турниров стало одним из решающих факторов в пользу нашей компании.",
    avatar: EDBg,
    name: "Екатерина Дроздецкая",
    company: "АРГУС",
    position: "Менеджер отдела по работе с персоналом",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Екатерина Дроздецкая",
    positionEng: "Менеджер отдела по работе с персоналом",
    year: 2022,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Спасибо команде IT-Events за классные спортивные и киберспортивные соревнования! Хочу отметить, что не только турниры между компаниями, но и внутренние мероприятия проходят великолепно. Ребята единственные смогли найти комментаторов с идеальным английским для нашего корпоративного турнира по CS:GO. Спасибо за вашу качественную работу!",
    avatar: MKBg,
    name: "Марьяна Храброва",
    company: "Эксперт-Система",
    position: "Старший специалист по бренду работодателя",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Марьяна Храброва",
    positionEng: "Старший специалист по бренду работодателя",
    year: 2022,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Хотим поблагодарить компанию IT-Events Team и Дениса Каланова лично за организацию корпоративных кибертурниров среди филиалов нашей компании. Команда организаторов работала слаженно и четко, все вопросы решались оперативно, турниры прошли на «ура», мы получаем позитивную обратную связь от сотрудников. Однозначно рекомендуем к сотрудничеству.",
    avatar: ESBg,
    name: "Елена Исакова",
    company: "Газпромнефть-Снабжение",
    position: "Координатор корпоративной программы well-being",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Елена Исакова",
    positionEng: "Координатор корпоративной программы well-being",
    year: 2022,
  },
  {
    confImg: <CyberLogo />,
    reviewText:
      "Благодарим IT-Events за великолепные турниры по Контр Страйк. Это регулярная активность на больших праздниках для сотрудников ЮMoney. Всегда четкий тайминг и внимательные организаторы, качество видео-ряда впечатляет даже просто зрителей и увлекает игроков события, максимально приближенные к реальности. Ведущие игр - профессиональные и известные в кругах игры комментаторы, это особенно нравится участникам.",
    avatar: EDm,
    name: "Лина Куркова",
    company: "ЮMoney",
    position: "Ведущий менеджер по персоналу ЮMoney",
    reviewTextEng:
      "DataArt teams have been participating in IT-Events sports events for many years and every time doing it with great pleasure. You create not just tournaments but gather like-minded people. I say with full confidence that IT-Events means more to us than just a tournament organizer. IT-Events helps build and support our sports communities, which are based on strong and emotional connections.",
    nameEng: "Лина Куркова",
    positionEng: "Ведущий менеджер по персоналу ЮMoney",
    year: 2022,
  },
];

export function SliderArrow(props: { to: "prev" | "next"; onClick?: any }) {
  const { onClick, to } = props;
  return (
    <div
      id="next"
      className={classNames(s.reviews__arrow, s[`reviews__arrow-${to}`])}
      onClick={onClick}
    >
      {to === "next" ? <RightArrow /> : <LeftArrow />}
    </div>
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <SliderArrow to="prev" />,
  nextArrow: <SliderArrow to="next" />,
};

export default function Reviews() {
  return (
    <Slider {...settings} className={s.reviews__list}>
      {reviews.map((review) => (
        <div className={s.reviews__item}>
          <div className={s.review}>
            <div className={s.infoWrapper}>
              <div className={s.review__conf}>{review.confImg}</div>
              <div className={s.confYear}>{review.year}</div>
            </div>
            <div className={s.review__text}>{review.reviewText}</div>
            <div className={s.review__author}>
              <div className={s["review__author-img"]}>
                <img
                  src={review.avatar}
                  alt=""
                  className={s["review__author-pic"]}
                />
              </div>
              <div className={s["review__text-content"]}>
                <span className={s.review__name}>
                  <AuthorName authorName={review.name} />
                </span>
                <span className={s.review__company}>{review.position}</span>
                <span className={s.review__position}>{review.company} </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

function AuthorName(props: { authorName: string }) {
  const { authorName } = props;

  const names = authorName.split(" ");

  return (
    <>
      {names.map((name, index) => {
        if (index + 1 === names.length) {
          return <span>{name}</span>;
        }
        return (
          <>
            <span>{name}</span>
            <br />
          </>
        );
      })}
    </>
  );
}
