import classNames from 'classnames';
import { useContext } from 'react';

import { StateContext } from '../App/App';
import s from './Hero.module.scss';
import { ReactComponent as Arrow } from './images/Arrow.svg';

export interface HeroDataProps {
    title: string;
    youTubeCode: string;
    popUpVideoTitle: string;
    video: string;
    bg: string;
    id: string;
    sectionTitle: string;
    buttonAction: 'watch' | 'link';
    productLink?: string;
}

export default function Hero(props: HeroDataProps) {
    const { sectionTitle, buttonAction = 'watch', productLink } = props;
    const { setIsOpenedPopUp, setPopUpComponent, isOpenedPopUp } = useContext(StateContext);
    const PopUpVideoComponent = () => {
        return (
            <div
                className={classNames(s.wrapper, isOpenedPopUp && s.zoomIn)}
                onKeyDown={(event) => {
                    if (event.key === 'Escape') {
                        setIsOpenedPopUp(false);
                    }
                }}
            >
                <div
                    className={s.closeIcon}
                    onClick={() => {
                        setIsOpenedPopUp(false);
                    }}
                ></div>
                <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${props.youTubeCode}?autoplay=1`}
                    title={props.popUpVideoTitle}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        );
    };

    return (
        <section className={classNames('section', s.hero)} style={{ backgroundImage: props.bg }}>
            <video className={s.video} src={props.video} poster={props.bg} autoPlay muted loop />
            <div className={s.heroFilter}></div>
            <div className={classNames('wrapper', s.heroWrapper)}>
                <div className={s.heroText}>
                    <div className={s.heroTitle}>
                        Work Sport Balance
                        <div className={classNames(s.subtitle, s[`${props.id}`])}>
                            {props.title}
                        </div>
                    </div>
                    <div className={s.heroSubtitle}>{sectionTitle}</div>
                    {buttonAction === 'watch' ? (
                        <button
                            onClick={() => {
                                setIsOpenedPopUp(true);
                                setPopUpComponent(<PopUpVideoComponent />);
                            }}
                            className={s.button}
                        >
                            СМОТРЕТЬ <Arrow className={s.arrow} />
                        </button>
                    ) : (
                        <a href={productLink} target="_blank" rel="noreferrer" className={s.button}>
                            Заказать услугу <Arrow className={s.arrow} />
                        </a>
                    )}
                </div>
            </div>
        </section>
    );
}
