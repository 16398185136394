import Clients from '../Clients';
import Contacts from '../Contacts';
import Footer from '../Footer';
import Hero, { HeroDataProps } from '../Hero';
import Leagues from '../Leagues';
import MainServices from '../MainServices';
import OtherProjects from '../OtherProjects';
import OurNumbers from '../OurNumbers';
import Popup from '../Popup';
import Projects from '../Projects';
import Service from '../Service';
import SubscribeForm from '../SubscribeForm';
import TeamGallery from '../TeamGallery';
import Values from '../Values';
import WeSection from '../WeSection';
import heroPic from './images/hero.png';
import videoBg from './images/hero_video.mp4';

export default function MainContainer(props: { isOpenedPopUp: boolean }) {
    return (
        <>
            <Hero {...heroData} />
            <Projects />
            <Leagues />
            <MainServices />
            <Service />
            <Values />
            <Clients />
            <WeSection />
            <OurNumbers />
            <OtherProjects />
            <TeamGallery />
            <SubscribeForm />
            <Contacts />
            <Footer />
            {props.isOpenedPopUp && <Popup />}
        </>
    );
}

const heroData: HeroDataProps = {
    bg: heroPic,
    video: videoBg,
    youTubeCode: 'uXkgs-aB9o0',
    id: 'main',
    title: '',
    popUpVideoTitle: 'Спортивный 2019 год Кубка IT-Challenge',
    sectionTitle: 'Организатор корпоративного спорта',
    buttonAction: 'watch',
};
