import classNames from "classnames";
import React from "react";

import { ReactComponent as Arrow } from "../../sharedImages/Arrow.svg";
import { ReactComponent as EnergyLogo } from "./images/energy.svg";
import { ReactComponent as FinanceLogo } from "./images/finance.svg";
import { ReactComponent as GamesLogo } from "./images/games.svg";
import { ReactComponent as ITCLogo } from "./images/ITC_logo.svg";
import s from "./Leagues.module.scss";


export default function Leagues() {
  const cards = [

    {
      title: "IT-CHALLENGE",
      subtitle: "cup",
      est: "est 2013",
      img: ITCLogo,
      link: "https://itccup.com/",
      id: "challenge",
    },
 //   {
  //    title: "Finance",
 //     subtitle: "cyber games",
 //     est: "est 2022",
 //     img: FinanceLogo,
 //     link: "https://documentcloud.wondershare.com/clientShare/review/bpcFVoayn8Msp0rtjRcN4eEtbxbi1cAalA8CIbNy7RBEge_yCX3eVC9qRJHb7FRJBGqzBUPh6I9bUStRjSZwiw",
 //     id: "finance",
 //   },
    {
      title: "IT CYBER",
      subtitle: "GAMES",
      est: "est 2020",
      img: GamesLogo,
      link: "http://itcybergames.ru/",
      id: "cyber",
    },
  //  {
  //    title: "Energy",
  //    subtitle: "cyber games",
  //    est: "est 2022",
  //    img: EnergyLogo,
  //    link: "https://documentcloud.wondershare.com/clientShare/review/bpcFVoayn8Msp0rtjRcN4Ur7P4ue8dg5MSfJgdsAzTgsu20EPReWj6T-zkiLYD25gvtlSJ0CG50LWnQTBljEBw",
   //   id: "energy",
  //  },
  ];
  return (
    <section className={classNames("section", s.leaguesSection)}>
      <div className="wrapper">
        <div className="anchor-place" id="leagues"></div>
        <h2 className={classNames("section__title", s.leaguesTitle)}>ЛИГИ</h2>
        <ul className={s.leaguesList}>
          {cards.map((card) => (
            <a
              href={`${card.link}`}
              className={classNames(s.leaguesItem, s[`leaguesItem-${card.id}`])}
              target="_blank"
              rel="noopener noreferrer"
              key={card.id}
            >
              <div className={s.leaguesIconBlock}>
                <card.img className={s.leaguesIcon} />
                <div className={s.leaguesEst}>{card.est}</div>
              </div>
              <div className={s.leagueTextBlockWrapper}>
                <div className={s.leagueTextBlock}>
                  <span className={s.leagueTitle}>{card.title}</span>
                  <span className={s.leagueSubTitle}>{card.subtitle}</span>
                </div>
                <Arrow className={s.arrow} />
              </div>
            </a>
          ))}
        </ul>
      </div>
    </section>
  );
}
