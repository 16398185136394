import classNames from "classnames";

import s from "./Footer.module.scss";

export default function Footer() {
  const links = [
    { link: "https://it-events.com/company", title: "О НАС" },
    { link: "https://it-events.com", title: "IT-EVENTS.COM" },
    { link: "https://hrapiconf.com/", title: "HR API" },
    { link: "https://ostconf.com/", title: "OST CONF" },
    { link: "https://golangpiter.com/", title: "GOLANG PITER" },
    { link: "https://it-challenge.ru/", title: "IT-CHАLLENGE CUP" },
    {
      link: "#",
      title: "WORK SPORT BALANCE",
      active: true,
    },
  ];
  return (
    <section className={classNames("section", s.footerMenuSection)}>
      <div className="wrapper">
        <div className={s.footerMenu}>
          <ul className={s.footerMenuList}>
            {links.map((link) => (
              <li className={s.footerMenuItem}>
                <a
                  href={link.link}
                  className={classNames(
                    s.footerMenuLink,
                    link.active ? s.footerMenuLinkActive : null
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
          <div className={s.footerMenuCopy}>
            © {new Date().getFullYear()} IT-Events, LTD
          </div>
        </div>
      </div>
    </section>
  );
}
