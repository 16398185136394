import { CapabilitiesSlide } from '../../../Capabilities';
import slidePic1 from './images/bike.jpg';
import slidePic2 from './images/brain.jpg';
import slidePic3 from './images/chess.jpg';
import slidePic4 from './images/goal.jpg';
import slidePic6 from './images/kicker.jpg';
import slidePic9 from './images/match_ball.jpg';
import slidePic8 from './images/race.jpg';
import slidePic10 from './images/run.jpg';
import slidePic11 from './images/sailing.jpg';
import slidePic12 from './images/snow_fest.jpg';
import slidePic7 from './images/strike.jpg';
import slidePic13 from './images/top_gun.jpg';
import slidePic5 from './images/voleyball.jpg';

export const capabilitiesClassicItems: CapabilitiesSlide[] = [
    {
        title: 'Проведение вело-кросс-кантри турнира',
        img: slidePic1,
        youTubeCode: '7HLMlUALZgk',
    },
    {
        title: 'Проведение турнира по настольному теннису',
        img: slidePic9,
        youTubeCode: 'SLY-pjk0BvY',
    },
    {
        title: 'Проведение турнира по шахматам',
        img: slidePic3,
        youTubeCode: 'uHcL5XukiBo',
    },
    {
        title: 'Проведение квиза',
        img: slidePic2,
        youTubeCode: 'WCXZ-8AAQ3Y',
    },
    {
        title: 'Проведение турнира по картингу',
        img: slidePic8,
        youTubeCode: 'U_WPsn1cL1M',
    },
    {
        title: 'Проведение турнира по боулингу',
        img: slidePic7,
        youTubeCode: 'ZvIYnQWwpe8',
    },
    {
        title: 'Проведение турнира по кикеру',
        img: slidePic6,
        youTubeCode: 'GL3zW81WwhQ',
    },
    {
        title: 'Проведение турнира по футболу',
        img: slidePic4,
        youTubeCode: 'bQ8ii934H-Y',
    },
    {
        title: 'Проведение турнира по волейболу',
        img: slidePic5,
        youTubeCode: 'Y6o7ho7zVuA',
    },
    {
        title: 'Проведение турнира по бегу',
        img: slidePic10,
        youTubeCode: 'Qv2FQ0_plcg',
    },
    {
        title: 'Проведение парусной регаты',
        img: slidePic11,
        youTubeCode: 'gQQoAcyfbJg',
    },
    {
        title: 'Проведение турнира по пейнтболу',
        img: slidePic13,
        youTubeCode: '6Kbr2_Mqf6A',
    },
    {
        title: 'Проведение турнира по слалому',
        img: slidePic12,
        youTubeCode: 'JgJL3GLp1CE',
    },

];
