import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { ReactComponent as CloseIcon } from '../../sharedImages/close.svg';
import { StateContext } from '../App/App';
import { cards, CardServices } from './contents';
import s from './Service.module.scss';

export default function Service() {
    const { setIsOpenedPopUp, setPopUpComponent } = useContext(StateContext);

    return (
        <section className={classNames('section', s.service)}>
            <div className="wrapper">
                <div className="anchor-place" id="capabilities"></div>
                <h2 className={classNames('section__title', s.serviceSectionTitle)}>
                    ДОПОЛНИТЕЛЬНЫЕ услуги и возможности
                </h2>
                <ul className={s.serviceList}>
                    {cards.map((card) => (
                        <li
                            className={s.serviceItem}
                            onClick={() => {
                                setIsOpenedPopUp(true);
                                setPopUpComponent(<PopupServicesContainer card={card} />);
                            }}
                        >
                            <div className={s.serviceIcon}>{card.icon}</div>
                            <span className={s.serviceTitle}> {card.title} </span>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

function PopupServicesContainer(props: { card: CardServices }) {
    const { card } = props;
    const { setIsOpenedPopUp, setPopUpComponent } = useContext(StateContext);

    return (
        <div className={s.popupContentWrapper} onClick={(e) => e.stopPropagation()}>
            <div className={s.header}>
                <div className={s.icon}>{card.icon}</div>
                <div className={s.title}>{card.title}</div>
                <CloseIcon
                    className={s.closeIcon}
                    onClick={() => {
                        setIsOpenedPopUp(false);
                        setPopUpComponent(null);
                    }}
                />
            </div>
            <Content content={card.blocks} />
        </div>
    );
}

function Content(props: {
    content: {
        title: string;
        content: JSX.Element;
        pics?: string[] | undefined;
    }[];
}) {
    const { content } = props;
    const [activeTab, setActiveTab] = useState(0);
    const [isTabs, setIsTabs] = useState(false);

    useEffect(() => {
        if (content.length > 1) {
            setIsTabs(true);
        }
    }, [content.length]);

    const contentTab = content[activeTab];
    return (
        <>
            {isTabs ? (
                <div className={s.tabs}>
                    {content.map((block, i) => {
                        return (
                            <div
                                className={classNames(s.tab, activeTab === i && s['tab-active'])}
                                onClick={() => {
                                    setActiveTab(i);
                                }}
                            >
                                {block.title}
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div>{contentTab.content}</div>
            <PicsSlider pics={contentTab.pics} />
        </>
    );
}

const settings: Settings = {
    dots: true,
    infinite: true,
    prevArrow: <></>,
    nextArrow: <></>,
    speed: 500,
    autoplaySpeed: 3000,
};

function PicsSlider(props: { pics?: string[] }) {
    const { pics } = props;
    if (!pics || !pics.length) {
        return null;
    }
    const photosCount = pics.length;
    const slidesToShow = photosCount < 4 ? photosCount : 4;
    const autoplay = photosCount > 4;
    let slidesToScroll = undefined;
    if (autoplay) {
        slidesToScroll = 4;
    }

    return (
        <Slider
            {...settings}
            autoplay={autoplay}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            className={s.photos}
        >
            {pics.map((pic) => {
                return (
                    <div className={s.photoWrapper}>
                        <img src={pic} alt="" className={s.photo} />
                    </div>
                );
            })}
        </Slider>
    );
}
