import classNames from 'classnames';
import { useRef } from 'react';
import { Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowLeft } from '../../sharedImages/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../sharedImages/ArrowRight.svg';
import s from './CasesSlider.module.scss';
import { ReactComponent as PlayIcon } from './images/icons/play.svg';
import { slides, SlideStream } from './slides/slides';

import 'swiper/scss';
import 'swiper/scss/effect-fade';

export default function CasesSlider() {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <div className="anchor-place" id="cases"></div>
                <div className={s.sectionHeader}>
                    <h2 className={classNames('section__title', s.sectionTitle)}>Кейсы</h2>
                    {/* <div>Смотреть все кейсы</div> */}
                </div>
                <div className={s.gallery}>
                    <div className={classNames('prev', s.prev)} ref={navigationPrevRef}>
                        <ArrowLeft />
                    </div>
                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        modules={[Navigation, EffectFade]}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        className={s.swiper}
                        effect="fade"
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index} virtualIndex={index} className={s.swiperSlide}>
                                <CaseSlide slide={slide} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={classNames('next', s.next)} ref={navigationNextRef}>
                        <ArrowRight />
                    </div>
                </div>
            </div>
        </section>
    );
}

function CaseSlide(props: { slide: SlideStream }) {
    const { slide } = props;
    const { pics, rows, title, url, subTitle, urlBtnTitle = 'Смотреть запись трансляции' } = slide;
    return (
        <div className={s.slide}>
            <div className={s.contentWrapper}>
                <div className={s.slideHeading}>
                    <div className={s.slideTitle}>{title}</div>
                    <div className={s.slideSubTitle}>{subTitle}</div>
                </div>
                <div className={s.rows}>
                    {rows.map((row, i) => (
                        <div className={s.contentRow} key={`${title}-${i}`}>
                            <div className={s.rowIcon}>{row.icon}</div>
                            <div className={s.rowContent}>{row.text}</div>
                        </div>
                    ))}
                </div>
                {url ? (
                    <a href={url} target="_blank" rel="noreferrer" className={s.watchLink}>
                        <PlayIcon className={s.linkIcon} />
                        {urlBtnTitle}
                    </a>
                ) : null}
            </div>
            <div className={s.sliderGallery}>
                {pics.map((pic) => (
                    <div className={s.galleryPic} key={pic}>
                        <img src={pic} alt="" className={s.galleryImg} />
                    </div>
                ))}
            </div>
        </div>
    );
}
