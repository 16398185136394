import { ListItem } from '../../Approach';
import { Card } from '../../CardsList';
import s from '../../DisciplinePage/DisciplinePage.module.scss';
import { DisciplineCard } from '../../DisciplinesClassic';
import { ReactComponent as ArrowRight } from '../images/arrowRight.svg';
import { ReactComponent as BasketIcon } from '../images/icons/BASKET_ICON.svg';
import { ReactComponent as BikeIcon } from '../images/icons/BIKE_ICON.svg';
import { ReactComponent as BowlingIcon } from '../images/icons/BOWLING_ICON.svg';
import { ReactComponent as CartingIcon } from '../images/icons/CARTING_ICON.svg';
import { ReactComponent as ChessIcon } from '../images/icons/CHESS_ICON.svg';
import { ReactComponent as DartsIcon } from '../images/icons/Darts_ICON.svg';
import { ReactComponent as FootballIcon } from '../images/icons/FOOTBALL_ICON.svg';
import { ReactComponent as KickerIcon } from '../images/icons/KICKER_ICON.svg';
import { ReactComponent as PaintballIcon } from '../images/icons/Paintball_ICON.svg';
import { ReactComponent as PingPongIcon } from '../images/icons/PINGPONG_ICON.svg';
import { ReactComponent as QuizIcon } from '../images/icons/QUIZ_ICON.svg';
import { ReactComponent as RunIcon } from '../images/icons/RUN_ICON.svg';
import { ReactComponent as SailingIcon } from '../images/icons/SAILING_ICON.svg';
import { ReactComponent as SkiIcon } from '../images/icons/SKI_ICON.svg';
import { ReactComponent as SwimIcon } from '../images/icons/Swimm_ICON.svg';
import { ReactComponent as VolleyballIcon } from '../images/icons/VOLEYBALL_ICON.svg';
import BasketBg from '../images/photo/BASKET_PHOTO.png';
import BikeBg from '../images/photo/BIKE_PHOTO.png';
import CartingBg from '../images/photo/CARTING_PHOTO.png';
import ChessBg from '../images/photo/CHESS_PHOTO.png';
import DartsBg from '../images/photo/DARTS.png';
import FootballBg from '../images/photo/GOAL_PHOTO.png';
import KickerBg from '../images/photo/KICKER_PHOTO.png';
import VolleyballBg from '../images/photo/MATCH-BALL_PHOTO.png';
import PaintballBg from '../images/photo/Paintball_Photo.png';
import PingpongBg from '../images/photo/PINGPONG_PHOTO.png';
import QuizBg from '../images/photo/QUIZ_PHOTO.png';
import RunBg from '../images/photo/RUN_PHOTO.png';
import SailingBg from '../images/photo/SAILING_PHOTO.png';
import SkiBg from '../images/photo/SKI_PHOTO.png';
import BowlingBg from '../images/photo/STRIKE_PHOTO.png';
import SwimBg from '../images/photo/SWIMM.png';

export const classicDisciplinesCards: DisciplineCard[] = [
    {
        id: 'pingpong',
        title: 'Настольный теннис',
        icon: <PingPongIcon />,
        bg: PingpongBg,
        count: 8,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT MATCH POINT </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>19.05.2019 // </span>
                        22 команды <span className={s.coloredText}>//</span> 66 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/SLY-pjk0BvY"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_263577377"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14334" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.04.2019 // </span>6 команд{' '}
                        <span className={s.coloredText}>//</span> 18 участников{' '}
                        <span className={s.coloredText}>//</span> Москва{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/YPwR3929N_I"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_262641388"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/12384" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>18.03.2018 // </span>
                        21 команда <span className={s.coloredText}>//</span> 63 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/z9aD3jcyNU4"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_251743544"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/10917" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>19.02.2017 // </span>
                        24 команды <span className={s.coloredText}>//</span> 72 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/hsfheDWBX-4"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288110250"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/8526" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>20.03.2016 // </span>
                        17 команд <span className={s.coloredText}>//</span> 51 участник{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/iAUPKBI-tMg"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_229605768"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/5907" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>15.03.2015 // </span>
                        20 команд <span className={s.coloredText}>//</span> 60 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/R6Bl5-I-GII"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_212936459"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/3959" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>15.03.2015 // </span>
                        32 команды <span className={s.coloredText}>//</span> 96 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_212936459"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/2079" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Турнир IT Match Point в рамках IT-Challenge Cup стал одним из самых
                        популярных состязаний в рамках Кубка.
                        <br />
                        Cостав команды – 3 человека. Турнир проходит в два этапа (групповой и
                        плей-офф).
                        <br />
                        Партия считается выигранной, когда один из игроков набрал 11 очков с
                        минимальным разрывом в 2 очка. Игроки по очереди делают по 2 подачи. Партия
                        между игроками продолжается до 2-х побед в личных встречах, за исключением
                        финальных игр за 1-е и 2-е место, которые ведутся до 3-х побед.
                        <br />
                    </div>
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>AZUR</div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.10.2020 </span>
                        <span className={s.coloredText}>//</span> 49 участников{' '}
                        <span className={s.coloredText}>//</span> Москва{' '}
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Участники были распределены по двум условным группам Pro и Basic, где первые
                        в среднем сильнее вторых. <br />
                        Далее провели жеребьевку и распределили участников по группам внутри своих
                        категорий. Для проведения турнира были привлечены двое профессиональных
                        судей и арендован второй дополнительный теннисный стол (аналогичный по
                        характеристикам имеющемуся в компании). <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'chess',
        title: 'Шахматы',
        icon: <ChessIcon />,
        bg: ChessBg,
        count: 7,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT CHESS </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>09.02.2020 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 24 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_270656870"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/17049" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>16.03.2019 // </span>
                        10 команд <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/uHcL5XukiBo"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_261867729"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14268" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.04.2018 // </span>
                        15 команд <span className={s.coloredText}>//</span> 45 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/nkYZa8rd9cg"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_253374905"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/itchess2018" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.10.2017 // </span>
                        15 команд <span className={s.coloredText}>//</span> 45 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/7rVLCgeLNp8"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_248479327"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/8649" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>06.10.2016 // </span>
                        16 команд <span className={s.coloredText}>//</span> 48 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/AaiXWBOde18"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288110781"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/5908" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>10.04.2015 // </span>
                        13 команд <span className={s.coloredText}>//</span> 39 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/AZe2JBBLs_s"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_214180331"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/4022" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.04.2014 // </span>
                        12 команд <span className={s.coloredText}>//</span> 36 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_194181259"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a
                            href="https://itccup.com/events/2081/report"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командные турниры IT-Chess в рамках IT-Challenge Cup обычно проходят в
                        блиц-формате (контроль времени: 3 + 2) по швейцарской системе. Турнир
                        проводится по правилам ФИДЕ.
                        <br />
                        Состав команды - 3 чел. Команды самостоятельно заранее утверждают и сообщают
                        игроков 1-й, 2-й и 3-й доски (в процессе турнира изменить порядок доски
                        игроки не могут). Игрок на 1-й доске, как правило, самый сильный в команде.
                        Побеждает команда, набравшая наибольшее количество командных очков.
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'quiz',
        title: 'Квиз',
        icon: <QuizIcon />,
        bg: QuizBg,
        count: 7,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT BRAIN BATTLE </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.01.2020 // </span>
                        18 команд <span className={s.coloredText}>//</span> 108 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_270357256"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/17028" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.03.2019 // </span>
                        18 команд <span className={s.coloredText}>//</span> 100 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/WCXZ-8AAQ3Y"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_261478850"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14267" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.10.2018 // </span>7 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Москва{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/ry4GXCa-hbY"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_257559024"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/12016" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.03.2018 // </span>
                        26 команд <span className={s.coloredText}>//</span> 151 участник{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/a0ffBq34k_g"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_251970565"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/10918" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.03.2017 // </span>
                        28 команд <span className={s.coloredText}>//</span> 156 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/rJuLTyrisnI"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_242789701"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/8565" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>06.04.2016 // </span>
                        25 команд <span className={s.coloredText}>//</span> 140 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/ghae9nJrTdE"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_230386646"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/5921" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>17.10.2015 // </span>
                        18 команд <span className={s.coloredText}>//</span> 106 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/34W0Ccj8w_I"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_222498184"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/5113" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командный турнир IT Brain Battle, проходящий как часть IT-Challenge Cup,
                        стал одним из самых популярных состязаний в рамках Кубка. Турнир традиционно
                        проходит в два тайма по 20 вопросов каждый. Вопросы разного уровня сложности
                        и на разные темы. В случае, если по итогам двух таймов не удается однозначно
                        определить 1, 2 и 3 места - назначаются интеллектуальные пенальти. Пенальти
                        - это блок из 5 вопросов, после который вопросы задаются по одному до ошибки
                        одной из команд.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'carting',
        title: 'Картинг',
        icon: <CartingIcon />,
        bg: CartingBg,
        count: 7,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT RACE </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.06.2021 // </span>
                        12 команд <span className={s.coloredText}>//</span> 60 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/5rvVH1426wc"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Запись онлайн трансляции
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_280293701"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/17212" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>08.06.2019 // </span>
                        14 команд <span className={s.coloredText}>//</span> 70 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/U_WPsn1cL1M"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_263728951"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14338" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>02.06.2018 // </span>
                        20 команд <span className={s.coloredText}>//</span> 100 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/FzavB1r-t_Q"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_254193404"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/ITRace_2018" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.06.2017 // </span>
                        16 команд <span className={s.coloredText}>//</span> 80 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/jG0zaQjBApc"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_244831937"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/ITRace_2017" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.05.2016 // </span>
                        16 команд <span className={s.coloredText}>//</span> 80 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/K0L3EpjrQ2A"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288110909"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/ITRace_2016" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>20.09.2015 // </span>
                        14 команд <span className={s.coloredText}>//</span> 56 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_221058481"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITRace_2015" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>13.03.2014 // </span>
                        13 команд <span className={s.coloredText}>//</span> 52 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_193282618"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITRace_2014" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командные турниры IT-Race в рамках IT-Challenge Cup традиционно проходят в
                        формате 4-х часовой гонки Ле-Ман.
                        <br />
                        Состав команды - 5 пилотов. Каждый пилот проводит на трассе не менее 40 и не
                        более 60 минут. Время на смену пилотов - 30 сек. Команда сама определяет
                        время смены пилотов и их очередность.
                        <br />
                        Побеждает команда, проехавшая наибольшее количество кругов за 240 минут.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'bowling',
        title: 'Боулинг',
        icon: <BowlingIcon />,
        bg: BowlingBg,
        count: 7,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT STRIKE </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.12.2019 // </span>
                        10 команд <span className={s.coloredText}>//</span> 50 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/ZvIYnQWwpe8"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_269242293"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14343" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>08.12.2018 // </span>
                        13 команд <span className={s.coloredText}>//</span> 65 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/hBBICTZEOU8"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_258717267"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/10950" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>02.12.2017 // </span>
                        13 команд <span className={s.coloredText}>//</span> 65 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/y-9anJeKbWU"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_249704246"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/8652" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>10.12.2016 // </span>
                        12 команд <span className={s.coloredText}>//</span> 48 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288111163"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}>//</span>{' '}
                        <a href="https://itccup.com/events/5924" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.12.2015 // </span>
                        10 команд <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_224769159"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/4045" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>04.12.2014 // </span>
                        11 команд <span className={s.coloredText}>//</span> 44 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_207469754"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/2120" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.09.2013 // </span>
                        10 команд <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/am9uz2maJUs"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/1379" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командный турнир IT Strike проходит в 2 этапа (группа и плей-офф) в течение
                        одного игрового дня в формате «Беккер».
                        <br />
                        Вся команда играет как один игрок, каждый из членов команды закрывает в игре
                        два фрейма (1 и 6, 2 и 7, 3 и 8, 4 и 9, 5 и 10).
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'kicker',
        title: 'Кикер',
        icon: <KickerIcon />,
        bg: KickerBg,
        count: 6,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT's KICKER </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>06.07.2019 // </span>
                        15 команд <span className={s.coloredText}>//</span> 75 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/GL3zW81WwhQ"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_264254905"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14339" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.09.2018 // </span>
                        26 команд <span className={s.coloredText}>//</span> 145 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/Sv7P6yxAuDQ"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_256764656"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/10945" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>09.07.2017 // </span>
                        24 команды <span className={s.coloredText}>//</span> 132 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_245789818"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}>//</span>{' '}
                        <a href="https://itccup.com/events/8575" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.07.2016 // </span>
                        23 команды <span className={s.coloredText}>//</span> 48 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/Io5uxJBLGMU"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_233373900"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/5919" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.06.2015 // </span>
                        21 команда <span className={s.coloredText}>//</span> 115 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_217343186"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/4028" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>27.07.2014 // </span>
                        20 команд <span className={s.coloredText}>//</span> 101 участник{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_199807402"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/2120" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командный турнир IT's Kicker, проходящий в рамках IT-Challenge Cup, стал
                        знаковым событием не только в развитии IT-сообщества Санкт-Петербурга, но и
                        в популяризации этой игры среди айтишников.
                        <br />
                        С 2019 года турнир проходил в новом турнирном формате - 1 матч до 40 очков.
                        Каждый сет до большего числа, кратного 4-ом. <br />
                        Порядок сетов строился следующим образом: Double 1, Double 2, Single 1,
                        Single 2, Double 3.
                        <br />
                        Один игрок может быть заявлен максимум в 2-х сетах. Команда должна состоять
                        минимум из 4-х и максимум из 5-ти игроков.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'bike',
        title: 'Вело',
        icon: <BikeIcon />,
        bg: BikeBg,
        count: 6,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT BIKE FEST </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.09.2019 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/7HLMlUALZgk"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_266835472"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14340" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>09.09.2018 // </span>
                        19 команд <span className={s.coloredText}>//</span> 86 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/rC82vOiSmiM"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_256395671"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_256395959"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a
                            href="https://itccup.com/ITBikeFest_2018"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>09.09.2017 // </span>
                        18 команды <span className={s.coloredText}>//</span> 80 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/gLFIaev77mA"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_247490364"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a
                            href="https://itccup.com/ITBikeFest_2017"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>10.09.2016 // </span>
                        21 команда <span className={s.coloredText}>//</span> 99 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/s_u5KPbetIk"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_241543883"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a
                            href="https://itccup.com/ITBikeFest_2016"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>12.09.2015 // </span>
                        18 команд <span className={s.coloredText}>//</span> 84 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_220652763"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a
                            href="https://itccup.com/ITBikeFest_2015"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>20.09.2014 // </span>
                        11 команд <span className={s.coloredText}>//</span> 48 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_202968240"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a
                            href="https://itccup.com/ITBikeFest_2014"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Формат велотурнира IT Bike Fest в рамках IT-Challenge Cup практически не
                        менялся с момента его основания. <br />
                        Турнир проходил в 3 этапа: первая кросс-кантри гонка на короткую дистанцию
                        (1,5-3 км) с раздельным стартом, вторая гонка в разные гонки была разной
                        (вело-драг-рейсинг, вело-кросс-эндуро и другие) и, наконец, третья и главная
                        кросс-кантри гонка на 15-20 км. <br />
                        Кроме основного командного зачета (в состав команды входит 3-5 чел), каждый
                        участник боролся в личном зачете (отдельно мужчины и женщины).
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'football',
        title: 'Футбол',
        icon: <FootballIcon />,
        bg: FootballBg,
        count: 7,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT GOAL </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>11.07.2021 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 69 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/bQ8ii934H-Y"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_269242118"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/17248" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>23.11.2019 // </span>
                        24 команды <span className={s.coloredText}>//</span> 214 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/5mA1r-sncNo"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_269242118"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14342" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.11.2018 // </span>
                        24 команды <span className={s.coloredText}>//</span> 197 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/bQ8ii934H-Y"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_258715675"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITGoal_2018" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.10.2017 // </span>
                        21 команда <span className={s.coloredText}>//</span> 187 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_248751020"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_248480308"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITGoal_2017" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>27.11.2016 // </span>
                        16 команд <span className={s.coloredText}>//</span> 128 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288111447"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITGoal_2016" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.04.2015 // </span>
                        11 команд <span className={s.coloredText}>//</span> 88 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_202968240"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITGoal_2015" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>18.11.2014 // </span>
                        12 команд <span className={s.coloredText}>//</span> 96 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_202968240"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/ITGoal_2014" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Турнир IT Goal проходящий в рамках IT-Challenge Cup стал одним из самых
                        популярных состязаний в рамках Кубка.
                        <br />
                        Турнир по мини-футболу традиционно проходит в два этапа (групповой и
                        плей-офф). В команде должно быть минимум 5 и максимум 9 участников. Игра
                        между командами проходила в 2 тайма по 10 минут.
                        <br />
                        Официальный мяч турнира: Select Super League АМФР РФС (размер 4-ка).
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'volleyball',
        title: 'Волейбол',
        icon: <VolleyballIcon />,
        bg: VolleyballBg,
        count: 6,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT MATCH BALL </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>17.02.2019 // </span>
                        15 команд <span className={s.coloredText}>//</span> 112 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/Y6o7ho7zVuA"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_260820092"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14264" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.03.2018 // </span>
                        15 команд <span className={s.coloredText}>//</span> 116 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/zDUxbleR4aE"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_252659316"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/10919" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.04.2017 // </span>
                        16 команд <span className={s.coloredText}>//</span> 129 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/5rAuPgE9XSY"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_243428942"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_243636230"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/8567" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.10.2016 // </span>
                        15 команд <span className={s.coloredText}>//</span> 123 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/rHwMAGkwTJQ"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288111708"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/5923" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.10.2015 // </span>
                        14 команд <span className={s.coloredText}>//</span> 115 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/RtRPnxcLxoA"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_222964010"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/4044" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>29.11.2014 // </span>7 команд{' '}
                        <span className={s.coloredText}>//</span> 59 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_207015033"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/2092" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Турнир IT Match Ball в рамках IT-Challenge Cup традиционно проходит в
                        формате так называемого "корпоративного волейбола на песке", который
                        является упрощенной классикой на песке. Состав команды: 4 человека + до 5
                        запасных.
                        <br />
                        Партия до 15 очков. Игра до 2-х побед во всех встречах. Первая и вторая
                        партия играются на балансе до 20 очков. Третья партия играется без баланса.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'run',
        title: 'Бег',
        icon: <RunIcon />,
        bg: RunBg,
        count: 4,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT RUN </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.09.2019 // </span>
                        12 команд <span className={s.coloredText}>//</span> 48 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/Qv2FQ0_plcg"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_266836211"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_266860751"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/14341" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>16.06.2018 // </span>
                        14 команд <span className={s.coloredText}>//</span> 56 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/XA0XuPuz7IY"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_254427537"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_254429581"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/10947" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>23.09.2017 // </span>
                        16 команд <span className={s.coloredText}>//</span> 65 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_247523582"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_247565541"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/8648" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>17.09.2016 // </span>9 команд{' '}
                        <span className={s.coloredText}>//</span> 36 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_288112331"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/7291" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Турнир IT Run в рамках IT-Challenge Cup состоит из 4-х этапов эстафеты: 2
                        000, 3 000, 4 000 и 5 000 метров.
                        <br />
                        Особеннности турнира. Не менее двух этапов должны быть женскими. Один
                        участник не может бежать два этапа подряд.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'sailing',
        title: 'Парусная регата',
        icon: <SailingIcon />,
        bg: SailingBg,
        count: 3,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT SAILING </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.07.2021 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 32 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/gQQoAcyfbJg"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_280294086"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/21071" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>29.08.2020 // </span>7 команд{' '}
                        <span className={s.coloredText}>//</span> 28 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/gQQoAcyfbJg"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_275259169"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/17029" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.07.2019 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 32 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_264860405"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_264860478"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/14371" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командный турнир IT Sailing проходящий в рамках IT-Challenge Cup, стал еще
                        одним знаковым событием в череде спортивных событий Санкт-Петербурга по
                        парусному спорту. Гонки проходили на абсолютно одинаковых спортивных яхтах.
                        <br />
                        Состав команды - 4-5 человек. Турнир состоял из 8 гонок. Длина каждой
                        дистанции составляла приблизительно 3 000 метров. Общее время, проведенное
                        экипажами на воде, порядка 6-ти часов. Во время гонок на каждой яхте
                        присутствовал шкипер обеспечивающий безопасность, управление которому
                        передавалась только для предотвращения опасных ситуаций (вылет на форт,
                        попадание на мель).
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'paintball',
        title: 'Пейнтбол',
        icon: <PaintballIcon />,
        bg: PaintballBg,
        count: 3,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT TOP GUN </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.06.2017 // </span>9 команд{' '}
                        <span className={s.coloredText}>//</span> 72 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/6Kbr2_Mqf6A"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_245416793"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/8574" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>18.06.2016 // </span>
                        11 команд <span className={s.coloredText}>//</span> 88 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/1icVjVP9F0w"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_233373819"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/5916" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>06.06.2015 // </span>7 команд{' '}
                        <span className={s.coloredText}>//</span> 56 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_218777260"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <a href="https://itccup.com/events/4042" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Турнир IT Top Gun в рамках IT-Challenge Cup стал еще одним вариантом
                        спортивного состязания на свежем воздухе. <br />
                        Игры проходили параллельно на двух площадках: «Город» и «Лес». Капитаны
                        команд получили боекомплект в виде 2100 шаров с краской, которым они должны
                        были распорядиться на свое усмотрение на все игры группового этапа, затем
                        они использовали дополнительный боекомплект на каждый этап плей-офф.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'ski',
        title: 'Лыжи',
        icon: <SkiIcon />,
        bg: SkiBg,
        count: 1,
        winter: true,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT SNOW FEST </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>02.02.2019 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://youtu.be/JgJL3GLp1CE"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Отчетный видеоролик
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_260554458"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 1,
                            </a>
                        </span>
                        <span className={s.coloredText}> </span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_260554879"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом 2
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/12799" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Турнир-фестиваль IT Snow Fest в рамках IT-Challenge Cup проходил в формате
                        слалом-гигант. <br />
                        Состав команды - 4-5 участников. Участники сами выбирают тип снаряжения и
                        его соотношение в команде. <br />
                        Возможные дисциплины: мужчины лыжи, мужчины сноуборд, женщины лыжи, женщины
                        сноуборд. Один участник может быть заявлен только в 1-й дисциплине. Длина
                        склона примерно 1 км. Каждый участник имел 2 попытки, который засчитывались
                        в командном зачете. Помимо командного зачета, все участники боролись за
                        место в личном первенстве.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'basket',
        title: 'Баскетбол',
        icon: <BasketIcon />,
        bg: BasketBg,
        count: 1,
        winter: false,
        disabled: false,
        cardContent: (
            <>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT BASKET </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by IT-Challenge Cup</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>29.02.2020 // </span>8 команд{' '}
                        <span className={s.coloredText}>//</span> 72 участника{' '}
                        <span className={s.coloredText}>//</span> Санкт-Петербург{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a
                                href="https://vk.com/album-63469502_275258929"
                                className={s.textLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Фотоальбом
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>
                        <a href="https://itccup.com/events/17264" target="_blank" rel="noreferrer">
                            <ArrowRight className={s.arrowLink} />
                        </a>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Командный турнир IT Basket проходит в 2 этапа (группа и плей-офф) в течение
                        одного игрового дня. Встреча между командами состоит из 2–х четвертей по 10
                        минут грязного времени. Последние 2 минуты второй (она же и последняя)
                        четверти – чистое время. Перерыв между четвертями отсутствует. Официальный
                        мяч турнира - WILSON VTB Gameball.
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'swim',
        title: 'Плавание',
        icon: <SwimIcon />,
        bg: SwimBg,
        count: 0,
        winter: false,
        disabled: true,
        cardContent: <></>,
    },
    {
        id: 'darts',
        title: 'Дартс',
        icon: <DartsIcon />,
        bg: DartsBg,
        count: 0,
        winter: false,
        disabled: true,
        cardContent: <></>,
    },
];

export const typeCards: Card[] = [
    {
        front: 'ВНУТРИКОРПОРАТИВНОЕ',
        back: 'Соревнование между сотрудниками одной компании или между командами структурных подразделений',
    },
    {
        front: 'МЕЖКОРПОРАТИВНОЕ',
        back: 'Спортивное противостояние между игроками или командами из разных компаний, например, дружеский футбольный матч',
    },
    {
        front: 'МАРКЕТИНГОВОЕ',
        back: 'Хотите подогреть интерес аудитории к вашей рекламной кампании? Спортивный праздник точно привлечёт внимание, если ваша ЦА любит активный отдых',
    },
    {
        front: 'СПОНСОРСКОЕ',
        back: 'Выступаете спонсором некоммерческого проекта? Хотите, чтобы ваше участие запомнили? Устройте для своих подопечных незабываемый день спорта с нами',
    },
    {
        front: 'ПАРТНЕРСКОЕ',
        back: 'Проведение дружеской спортивной встречи между сотрудниками вашей компании и бизнес-партнёрами укрепит лояльные отношения',
    },
    {
        front: 'КЛИЕНТСКОЕ',
        back: 'Лучший подарок - это новые впечатления: азарт, драйв, радость и вкус победы… Устроим для ваших клиентов праздник, который точно запомнится!',
    },
];

export const formatCards: Card[] = [
    {
        front: 'ТУРНИР',
        back: 'Состязания между командами или отдельными игроками, с турнирной таблицей и призовыми местами',
    },
    {
        front: 'ЧЕМПИОНАТ',
        back: 'Соревнование на звание чемпиона какого-либо уровня (отдела, департамента, группы компаний и т.д.) в определённой спортивной дисциплине',
    },
    {
        front: 'СПАРТАКИАДА',
        back: 'Открытые соревнования в нескольких дисциплинах сразу, в которых принимает участие большое количество людей с разным уровнем подготовки',
    },
    {
        front: 'ШОУ-МАТЧ',
        back: 'Зрелищная битва с приглашёнными звёздами: популярными стримерами, блогерами и PRO игроками',
    },
    {
        front: 'ФЕСТИВАЛЬ',
        back: 'Много спортивных активностей на разный вкус на одной площадке с мастер-классами от профи и конкурсами, для участников с любым уровнем подготовки',
    },
    {
        front: 'СПОРТИВНОЕ ШОУ',
        back: 'Большое событие с насыщенной программой: от классической акробатики и эквилибра до трюкового футбола, паркура и черлидинга',
    },
];

export const approachItems: ListItem[] = [
    { title: 'Заполнение брифинга' },
    { title: 'Подготовка коммерческого предложения' },
    { title: 'Согласование всех аспектов проведения турнира' },
    { title: 'Разработка индивидуального регламента' },
    { title: 'Определение оптимальной турнирной сетки исходят из количества участников' },
    { title: 'Коммуникация и поддержка участников на этапе подготовки и проведения' },
    { title: 'Поиск и аренда площадок' },
    { title: 'Аренда и подготовка необходимого оборудования и спортивного инвентаря' },
    { title: 'Подготовка бригады квалифицированных судей' },
    { title: 'Оформление площадки (флаги, прессволы, шатры)' },
    { title: 'Создание баннеров для социальных сетей' },
    { title: 'Разработка ленгдинга спортивного мероприятия' },
    { title: 'Написание писем, анонсов' },
    { title: 'Проведение спортивного мероприятия' },
    { title: 'Получение обратной связи Ретроспектива' },
];
