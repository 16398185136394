import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import {useLocation} from "react-router-dom";

import {getPageSeoData, PageParams} from "../../helpers/seo";
import { StateContext } from "../App/App";
import Header from "../Header";
import s from "./Layout.module.scss";

interface childrenProps {
  isOpenedPopUp: boolean;
}

export default function Layout(props: {
  children: (props: childrenProps) => React.ReactElement;
}): React.ReactElement {
  const { isOpenedPopUp, setIsOpenedPopUp } = useContext(StateContext);
  const [topOffset, setTopOffset] = useState(0);

  const onScroll = () => {
    setTopOffset(window.pageYOffset);
  };

  window.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      setIsOpenedPopUp(false);
    }
  });
  const location = useLocation();
  let pageParams : PageParams = getPageSeoData(location.pathname);
  useEffect(() => {
    if (pageParams.title !== null && pageParams.title !== undefined) {
      document.title = pageParams.title;
    }
    if (pageParams.description !== null && pageParams.description !== undefined) {
      document.querySelector(
          'meta[name="description"]'
      )?.setAttribute(
          "content", pageParams.description
      );
    }
    if (pageParams.keywords !== null && pageParams.keywords !== undefined) {
      document.querySelector(
          'meta[name="keywords"]'
      )?.setAttribute(
          "content", pageParams.keywords
      );
    }
  }, [pageParams]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    if (isOpenedPopUp) {
      body[0].style.overflow = "hidden";
    } else {
      body[0].style.overflow = "visible";
    }
  }, [isOpenedPopUp]);

  return (
    <div className={classNames(s.app, isOpenedPopUp && s.popupOpened)}>
      <Header topOffset={topOffset} />
      {props.children({ isOpenedPopUp: isOpenedPopUp })}
    </div>
  );
}
