import classNames from 'classnames';
import { useState } from 'react';

import { Classic } from './Items/Classic';
import { Conference } from './Items/Conference';
import { Cyber } from './Items/Cyber';
import { Podcast } from './Items/Podcast';
import s from './StreamTypes.module.scss';

const blocks = [
    {
        title: 'КИБЕРСПОРТИВНЫЕ',
        id: '1',
        component: <Cyber />,
    },
    {
        title: 'спортивные',
        id: '2',
        component: <Classic />,
    },
    {
        title: 'Конференции и митапы',
        id: '3',
        component: <Conference />,
    },
    {
        title: 'Подкасты и радио',
        id: '4',
        component: <Podcast />,
    },
];

export default function StreamTypes() {
    const [activeItem, setActiveItem] = useState('1');
    const current = blocks.find((item) => item.id === activeItem)!;
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <div className="anchor-place" id="streamTypes"></div>
                <h2 className={classNames('section__title', s.sectionTitle)}>
                    ТРАНСЛЯЦИИ ПО ТИПАМ
                </h2>
                <div className={s.menu}>
                    {blocks.map((item) => {
                        return (
                            <div
                                className={classNames(
                                    s.menuItem,
                                    activeItem === item.id ? s.active : null,
                                )}
                                onClick={() => {
                                    setActiveItem(item.id);
                                }}
                            >
                                {item.title}
                            </div>
                        );
                    })}
                </div>
                <div className={s.itemContent}>{current.component}</div>
            </div>
        </section>
    );
}
