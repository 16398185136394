import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../sharedImages/Arrow.svg';
import s from './DisciplinesClassic.module.scss';
import { ReactComponent as WinterIcon } from './images/winter.svg';
export interface DisciplineCard {
    id: string;
    icon: JSX.Element;
    bg: string;
    cardContent: JSX.Element;
    title?: string;
    count?: number;
    winter?: boolean;
    disabled?: boolean;
    cyber?: boolean;
}

export function DisciplinesCard(props: { card: DisciplineCard }) {
    const { card } = props;
    const [isHovered, setHovered] = useState(false);
    const navigate = useNavigate();
    return (
        <li
            className={classNames(
                s.disciplinesItem,
                isHovered && s.cardHovered,
                card.disabled && s.cardDisabled,
            )}
            key={card.title}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
                if (!card.disabled) {
                    navigate(card.id);
                }
            }}
        >
            <div className={s.disciplinesCategory}>
                <div className={classNames(s.disciplinesIcon, s.disciplinesIconCyber)}>
                    {card.icon}
                </div>
                {card.title ? <div className={s.disciplinesName}>{card.title}</div> : null}
                <Arrow className={s.arrowIcon} />
            </div>
            <div className={s.disciplinesPic}>
                <img src={card.bg} alt="" className={s.disciplinesImg} />
            </div>
            {/* {card.count ? <div className={s.counter}>{card.count}</div> : null} */}
            {card.winter ? <WinterIcon className={s.winterIcon} /> : null}
        </li>
    );
}

export default function DisciplinesClassic(props: { cards: DisciplineCard[] }) {
    return (
        <section className={classNames('section', s.disciplines)}>
            <div className="wrapper">
                <div className="anchor-place" id="disciplines"></div>
                <h2 className="section__title">КЕЙСЫ</h2>
                <ul className={s.disciplinesList}>
                    {props.cards.map((card) => (
                        <DisciplinesCard card={card} />
                    ))}
                </ul>
            </div>
        </section>
    );
}
