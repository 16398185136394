import { useParams } from 'react-router-dom';

import Approach from '../Approach';
import CasesSlider from '../CasesSlider';
import Contacts from '../Contacts';
import DisciplinePage from '../DisciplinePage';
import DisciplinesClassic from '../DisciplinesClassic';
import { Equipment } from '../Equipment';
import Footer from '../Footer';
import Formats from '../Formats';
import Hero, { HeroDataProps } from '../Hero';
import StreamTypes from '../StreamTypes';
import StudioGallery from '../StudioGallery';
import SubscribeForm from '../SubscribeForm';
import Types from '../Types';
import { approachItems, formatCards, streamDisciplinesCards, typeCards } from './data';
import videoBg from './images/STREAM_bg-video_3.mp4';
import heroPic from './images/stream_photo.jpg';
import s from './StreamContainer.module.scss';

export default function StreamContainer() {
    let { disciplines } = useParams();

    const card = streamDisciplinesCards.find((card) => {
        return card.id === disciplines;
    });

    return (
        <>
            {card ? (
                <DisciplinePage
                    mainPath="stream"
                    card={card}
                    cyberDisciplinesCards={streamDisciplinesCards}
                    key={`stream-${card.id}`}
                />
            ) : (
                <>
                    <Hero {...heroData} />
                    <StreamTypes />
                    <Equipment />
                    <CasesSlider />
                    <StudioGallery />
                    <SubscribeForm />
                    <Contacts />
                    <Footer />
                </>
            )}
        </>
    );
}

const heroData: HeroDataProps = {
    bg: heroPic,
    video: videoBg,
    youTubeCode: 'uXkgs-aB9o0',
    id: 'stream',
    title: 'STREAM STUDIO',
    popUpVideoTitle: 'Спортивный 2019 год Кубка IT-Challenge',
    sectionTitle: 'ОРГАНИЗАТОР СПОРТИВНЫХ И ДЕЛОВЫХ ТРАНСЛЯЦИЙ',
    buttonAction: 'link',
    productLink: 'https://formsstudio.worksportbalance.com/',
};
