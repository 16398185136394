import TeamImg from '../../sharedImages/main.jpg';
import GallerySlider from '../GallerySlider';
import TeamImg1 from './images/photo_studio1.jpg';
import TeamImg2 from './images/photo_studio2.jpg';
import TeamImg3 from './images/photo_studio3.jpg';
import TeamImg4 from './images/photo_studio4.jpg';
import TeamImg5 from './images/photo_studio5.jpg';
import TeamImg6 from './images/photo_studio6.jpg';
import TeamImg7 from './images/photo_studio7.jpg';

const cards = [TeamImg2, TeamImg4, TeamImg1, TeamImg7, TeamImg3, TeamImg5, TeamImg6];

export default function StudioGallery() {
    return <GallerySlider slides={cards} title="НАША СТУДИЯ" />;
}
