import { useParams } from 'react-router-dom';

import { Capabilities } from '../Capabilities';
import ComplexApproach from '../ComplexApproach';
import Contacts from '../Contacts';
import { cyberDisciplinesCards } from '../CyberContainer/data';
import { CyberTypes } from '../CyberTypes';
import DisciplinePage from '../DisciplinePage';
import DisciplinesClassic, { DisciplineCard } from '../DisciplinesClassic';
import Footer from '../Footer';
import Format from '../Format';
import Hero, { HeroDataProps } from '../Hero';
import SubscribeForm from '../SubscribeForm';
import { capabilitiesCyberItems } from './data/CapabilitiesData';
import videoBg from './images/CYBER_bg_video.mp4';
import heroPic from './images/cyber_photo.jpg';

export default function CyberContainer() {
    let { disciplines } = useParams();

    const card = cyberDisciplinesCards.find((card) => {
        return card.id === disciplines;
    });

    return (
        <>
            {card ? (
                <DisciplinePage
                    mainPath="cyber"
                    card={card}
                    cyberDisciplinesCards={cyberDisciplinesCards}
                />
            ) : (
                <>
                    <Hero {...heroData} />
                    <ComplexApproach />
                    <Format />
                    <CyberTypes />
                    <DisciplinesClassic cards={cyberDisciplinesCards} />
                    <Capabilities slides={capabilitiesCyberItems} />
                    <SubscribeForm />
                    <Contacts />
                    <Footer />
                </>
            )}
        </>
    );
}

const heroData: HeroDataProps = {
    bg: heroPic,
    video: videoBg,
    youTubeCode: 'uXkgs-aB9o0',
    id: 'cyber',
    title: 'cyber',
    popUpVideoTitle: 'Спортивный 2019 год Кубка IT-Challenge',
    sectionTitle: 'ОРГАНИЗАТОР КИБЕРСПОРТИВНЫХ СОРЕВНОВАНИЙ',
    buttonAction: 'link',
    productLink: 'https://formsports.worksportbalance.com/',
};
