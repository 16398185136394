import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

import localeEn from './locales/en.json';
import localeRu from './locales/ru.json';

const resources = {
    RU: {
        translation: localeRu
    },
    EN: {
        translation: localeEn
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        debug: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
