import classNames from 'classnames';
import { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowLeft } from './images/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './images/ArrowRight.svg';
import s from './TeamGallery.module.scss';

export default function GallerySlider(props: { slides: string[]; title: string }) {
    const { slides, title } = props;
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    return (
        <section className={classNames('section', s.sectionTeam)}>
            <div className="wrapper">
                <div className="anchor-place" id="team"></div>
                <h2 className="section__title">{title}</h2>
                <div className={s.gallery}>
                    <div className={classNames('prev', s.prev)} ref={navigationPrevRef}>
                        <ArrowLeft />
                    </div>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={25}
                        loop={true}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        className={s.swiper}
                    >
                        {slides.map((img, index) => (
                            <SwiperSlide key={index} virtualIndex={index} className={s.swiperSlide}>
                                {/* <div className={s.sliderPic}> */}
                                <img src={img} className={s.sliderImg} alt="" />
                                {/* </div> */}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={classNames('next', s.next)} ref={navigationNextRef}>
                        <ArrowRight />
                    </div>
                </div>
            </div>
        </section>
    );
}
