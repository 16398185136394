import { ComplexApproachSlide } from '..';
import s from '../ComplexApproach.module.scss';
import GameAnimation from './GameAnimation.json';
import LiveAnimation from './LiveAnimation.json';
import PersonalityAnimation from './PersonalityAnimation.json';
import PrepareAnimation from './PrepareAnimation.json';
import SupportAnimation from './SupportAnimation.json';

export const slides: ComplexApproachSlide[] = [
    {
        title: 'Подготовка',
        key: 0,
        animatedIcon: PrepareAnimation,
        content: (
            <div className={s.slideContentWrapper}>
                <ul className={s.slideContentList}>
                    <li className={s.slideContentItem}>
                        Регистрация участников и балансировка команд
                    </li>
                    <li className={s.slideContentItem}>Настройка discord-сервера</li>
                    <li className={s.slideContentItem}>Коммуникации с игроками</li>
                    <li className={s.slideContentItem}>Аренда и настройка игровых серверов</li>
                    <li className={s.slideContentItem}>Подготовка регламента и схемы турнира</li>
                </ul>
            </div>
        ),
    },
    {
        title: 'Проведение',
        key: 1,
        animatedIcon: GameAnimation,
        content: (
            <div className={s.slideContentWrapper}>
                <ul className={s.slideContentList}>
                    <li className={s.slideContentItem}>
                        Контроль и соблюдения правил и регламента
                    </li>
                    <li className={s.slideContentItem}>
                        Фиксирование нарушений, вынесение предупреждений и наказаний
                    </li>
                    <li className={s.slideContentItem}>Ведение счёта и турнирных таблиц</li>
                    <li className={s.slideContentItem}>
                        Мониторинг стабильности работы игровых серверов
                    </li>
                    <li className={s.slideContentItem}>Техническое сопровождение игроков</li>
                </ul>
            </div>
        ),
    },
    {
        title: 'трансляция',
        key: 2,
        animatedIcon: LiveAnimation,
        content: (
            <div className={s.slideContentWrapper}>
                <ul className={s.slideContentList}>
                    <li className={s.slideContentItem}>Профессиональные комментаторы</li>
                    <li className={s.slideContentItem}>Живой обсервинг</li>                    
                    <li className={s.slideContentItem}>Графическое оформление и анимация</li>
                    <li className={s.slideContentItem}>
                        Стриминг на любые платформы: YouTube, VK, Twitch и другие
                    </li>
                    <li className={s.slideContentItem}>Трансляция в высоком качестве: 4k60fps</li>
                    <li className={s.slideContentItem}>Повторы и хайлайты</li>
                </ul>
            </div>
        ),
    },
    {
        title: 'Обратная связь',
        key: 3,
        animatedIcon: SupportAnimation,
        content: (
            <div className={s.slideContentWrapper}>
                <ul className={s.slideContentList}>
                    <li className={s.slideContentItem}>Сбор и анализ фидбэка от участников</li>
                    <li className={s.slideContentItem}>Предоставление статистики просмотров</li>
                    <li className={s.slideContentItem}>Отработка негативных отзывов</li>
                    <li className={s.slideContentItem}>
                        Формирование отчётов для руководства и менеджеров
                    </li>
                    <li className={s.slideContentItem}>
                        Проведение ретроспективы
                    </li>
                    <li className={s.slideContentItem}>
                        Набор рекомендаций для проведения будущих турниров
                    </li>
                </ul>
            </div>
        ),
    },
    {
        title: 'Персонализация',
        key: 4,
        animatedIcon: PersonalityAnimation,
        content: (
            <div className={s.slideContentWrapper}>
                <ul className={s.slideContentList}>
                    <li className={s.slideContentItem}>Cоздание уникального графического дизайна</li>
                    <li className={s.slideContentItem}>Использование кастомного игрового HUD’a</li>
                    <li className={s.slideContentItem}>Разработка лендинга турнира</li>
                    <li className={s.slideContentItem}>
                        Создание отчетного видеоролика
                    </li>
                    <li className={s.slideContentItem}>
                        Создание ролика с хайлатами
                    </li>
                    <li className={s.slideContentItem}>
                        Организация тренировочного процесса
                    </li>
                    <li className={s.slideContentItem}>
                        Студия аналитики
                    </li>
                </ul>
            </div>
        ),
    },
    
];
