import classNames from "classnames";

import { ReactComponent as Logo } from "./images/IT-Events_logo.svg";
import s from "./WeSection.module.scss";


export default function WeSection() {
  return (
    <section className={classNames("section", s.weSection)}>
      <div className="wrapper">
        <div className="anchor-place" id="about"></div>
        <h2 className="section__title">Кто Мы</h2>
          <span className={s.textContent}>
            <span className={s.accent}>WSB Team</span> — команда
            организаторов корпоративных спортивных эвентов. <br />
            <br />С 2012 года мы реализовали уже сотни спортивных турниров по
            разным дисциплинам, среди которых кикер, шахматы, боулинг, слалом,
            парусная регата, картинг, футбол, волейбол, пинг-понг, баскетбол,
            бег, вело кросс-кантри. 
            <br />
            <br />В 2020 году мы открыли для себя киберспортивное направление и
            провели турниры по CS:GO, DOTA 2, League of Legends, Quake III, Mortal
            Kombat, FIFA и другие.
            <br />
            <br />
            <span className={s.accent}>Мы</span> отлично делаем как небольшие первенства
            на 2 команды, так и международные чемпионаты на большое количество участников,
            продолжительностью несколько недель.
            <br />
            <br />
            <span className={s.accent}>Мы</span> одинаково хорошо справимся с проведением
            оффлайн турнира (например, по картингу или футболу) и онлайн чемпионата (по
            шахматам или CS:GO). Наши клиенты — большие и малые компании, концерны и стартапы,
            как международные, так и локальные.
            <br />
            <br /> <span className={s.accent}>Мы</span> обладаем богатым опытом проведения
            спортивных массовых мероприятий и будем рады использовать его, чтобы реализовать ваши идеи!
          </span>
      </div>
    </section>
  );
}
