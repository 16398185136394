import classNames from 'classnames';

import s from './OurNumbers.module.scss';

const items = [
    { count: '10+', title: 'лет занимаемся эвентами' },
    { count: '300+', title: 'довольных клиентов' },
    { count: '100+', title: 'киберспортивных турниров' },
    { count: '80+', title: 'спортивных соревнований' },
    { count: '250+', title: 'онлайн трансляций' },
];

export default function OurNumbers() {
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <h2 className={classNames('section__title', s.sectionTitle)}>О нас в цифрах</h2>
                <div className={s.items}>
                    {items.map((item) => {
                        return (
                            <div className={s.item}>
                                <div className={s.itemCount}>{item.count}</div>
                                <div className={s.itemTitle}>{item.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
