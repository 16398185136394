import s from '../StreamTypes.module.scss';
import { ReactComponent as CyberImg } from './images/Cyber.svg';

export function Cyber() {
    return (
        <div className={s.itemWrapper}>
            <div className={s.content}>
                <ul className={s.services}>
                    <li className={s.service}>Профессиональные комментаторы</li>
                    <li className={s.service}>Живой обсервинг</li>
                    <li className={s.service}>Индивидуальное графическое оформление</li>
                    <li className={s.service}>Создание анимаций и стингеров</li>
                    <li className={s.service}>
                        Стриминг на любые платформы: YouTube, VK, Twitch и другие
                    </li>
                    <li className={s.service}>Трансляция в высоком качестве: 4k60fps</li>
                    <li className={s.service}>Повторы и хайлайты</li>
                    <li className={s.service}>Титровальная машина</li>
                    <li className={s.service}>Кастомный игровой HUD</li>
                    <li className={s.service}>Студия аналитики</li>
                </ul>
                <div className={s.tags}>
                    <div className={s.tag}>CS:GO</div>
                    <div className={s.tag}>DOTA 2</div>
                    <div className={s.tag}>LOL</div>
                    <div className={s.tag}>FIFA</div>
                    <div className={s.tag}>MK</div>
                    <div className={s.tag}>и другие</div>
                </div>
            </div>
            <div className={s.pic}>
                <CyberImg />
            </div>
        </div>
    );
}
