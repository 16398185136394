import { ListItem } from '../../Approach';
import { Card } from '../../CardsList';
import s from '../../DisciplinePage/DisciplinePage.module.scss';
import { DisciplineCard } from '../../DisciplinesClassic';
import { ReactComponent as ArrowRight } from '../images/arrowRight.svg';
import { ReactComponent as AssettoIcon } from '../images/icon/assetto.svg';
import { ReactComponent as CsgoIcon } from '../images/icon/CSGO.svg';
import { ReactComponent as DotaIcon } from '../images/icon/DOTA2.svg';
import { ReactComponent as FallguysIcon } from '../images/icon/FallGuys.svg';
import { ReactComponent as FifaIcon } from '../images/icon/FIFA.svg';
import { ReactComponent as LolIcon } from '../images/icon/LOL.svg';
import { ReactComponent as MkIcon } from '../images/icon/MK.svg';
import { ReactComponent as OverwatchIcon } from '../images/icon/Overwatch.svg';
import { ReactComponent as PubgIcon } from '../images/icon/pubg.svg';
import { ReactComponent as QuakeIcon } from '../images/icon/Quake.svg';
import { ReactComponent as TanksIcon } from '../images/icon/tanks.svg';
import { ReactComponent as ValoIcon } from '../images/icon/Valorant.svg';
import AssettoBg from '../images/png/assetto.png';
import CsgoBg from '../images/png/CSGO.png';
import DotaBg from '../images/png/Dota.png';
import FallguysBg from '../images/png/FallGuys.png';
import FifaBg from '../images/png/FIFA.png';
import LolBg from '../images/png/LOL.png';
import MkBg from '../images/png/MK.png';
import OverwatchBg from '../images/png/Overwatch.png';
import PubgBg from '../images/png/pubg.png';
import QuakeBg from '../images/png/Quake.png';
import TanksBg from '../images/png/tanks.png';
import ValoBg from '../images/png/valo.png';

export const cyberDisciplinesCards: DisciplineCard[] = [
    {   id: 'counter_strike',
        icon: <CsgoIcon />,
        bg: CsgoBg, count: 41,
        cyber: true,
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Counter-Strike: Global Offensive (CS:GO, кс, ксго) - шутер от первого лица.<br />
                        Основной формат соревнований — командный 5 на 5 (существуют вариации 1x1, 2x2).<br />
                        CS:GO - одна из самых доступных и понятных игр для новичков в киберспорте.<br />
                        Игра бесплатная, доступна на Windows и Mac OS.<br />
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT CYBER GAMES </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by Work Sport Balance</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>28.02.2022 - 20.03.2022 </span>
                        <span className={s.coloredText}>//</span> 13 команд{' '}
                        <span className={s.coloredText}>//</span> 69 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLegMkFkgoXwZTKt0B6Nj7Ix97eKa7vqU3" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.10.2022 - 22.10.2022 </span>
                        <span className={s.coloredText}>//</span> 18 команд{' '}
                        <span className={s.coloredText}>//</span> 94 участника{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFt7yDCmfLWaJ3f8sKkIkFv" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.10.2021 - 12.11.2021 </span>
                        <span className={s.coloredText}>//</span> 27 команд{' '}
                        <span className={s.coloredText}>//</span> 146 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jHSjoWzK9d4ucbTodCiXRnu" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>15.10.2020 - 12.12.2020 </span>
                        <span className={s.coloredText}>//</span> 20 команд{' '}
                        <span className={s.coloredText}>//</span> 107 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFQvYSWkkfPjzBtQBWe8atO" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist 
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.04.2020 - 08.05.2020 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 29 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFQvYSWkkfPjzBtQBWe8atO" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        «IT Cyber Games» - регулярный чемпионат среди ИТ компании.<br />
                        3 дисциплины: CS:GO, DOTA 2, League of Legends.<br />
                        География участников турнира: от Владивостока до Праги.<br />
                        Обычно чемпионат проходит 2 раза в год: зима-весна и осень-зима. Проходит в 2 этапа: групповая стадия (квалификация) и плей-офф. Все игры чемпионата транслируются в YouTube-канале Work Sport Balance<br />
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>AZUR GAMES </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>27.05.2023 - 28.05.2023</span>
                        <span className={s.coloredText}>//</span> 11 команд{' '}
                        <span className={s.coloredText}>//</span> 55 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/crd2ZjYE-Og" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/-Y--_yJ4tdQ" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/s2UI2ee_Pn4" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>            
                
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>28.05.2022 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/cSyeIF3-b8s" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/I4ZNuP5LwD0" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/-_z-eDEN3Wc" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>            
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>19.06.2021 - 20.06.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/8mFqHgtj0OY" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/NTJ5civPx7Y" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>       
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Argus </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
         
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.04.2023 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/64OaV58ujEI" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/LNin7AhbMtQ" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>     
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.02.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/-XRp4mh8hDY" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/hJsK0ZjWHrk" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/v2tMr7rtJr4" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>  
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Газпромнефть - Снабжение</div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>13.08.2022 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 32 участника{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/NEFyOMgLuso" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/tJskn-0PoS8" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>16.07.2022 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/84RitifbK_k" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/f04jxgSZfek" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>01.10.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/sfqJDN6n7mM" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/d4YUgQ-a_5o" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>
                </div>
                
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Beget </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.06.2023 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/Nwiyy_DTsv0" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/YzeG_hUUMHE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>             
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>FLSonAir  </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.02.2022 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/ovnoQr_K_xQ" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/SQi9vOlR56g" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>ЮMoney </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.02.2022 </span>
                        <span className={s.coloredText}>//</span> 3 команды{' '}
                        <span className={s.coloredText}>//</span> 15 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/vGetKvQ21B0" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.12.2021 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/kI_OXrTXBTA" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>       
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>05.03.2021 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/B1DuOaB1k3E" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.12.2020 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/9ULju7wI3HE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/5zFNiPzoOVo" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/QHvU-aU1avY" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>     
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>SRG IT </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.02.2022 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/E2sDHaUZ_Wo" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/itVvogvMkCk" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Orion  </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>10.12.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/OM1qYcb27Ec" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/53Xi87h0aU4" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Zyfra </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>19.11.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/Uwwil4tlk7A" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/fs6ad0b2h2I" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>     
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.05.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/aqiA1P-J9k8" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/YXkQgtLocOo" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>          
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Quantori </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>30.09.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/mDZboGNmGo8" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/_-QZsd1yIW4" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Space307 & dataduck </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.09.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/c_bl_UwlzZU" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/7sDuj2IYHng" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Wrike </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>16.09.2021 - 17.09.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/w2q-arl3jB4" className={s.textLink} target="_blank" rel="noreferrer">
                                RU Stream
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/fvQxJRZsW5Q" className={s.textLink} target="_blank" rel="noreferrer">
                                ENG Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>RJ Games</div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.06.2021 </span>
                        <span className={s.coloredText}>//</span> 3 команды{' '}
                        <span className={s.coloredText}>//</span> 15 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/LxIHuc2qOgE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Veeam </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>14.04.2021 - 17.04.2021 </span>
                        <span className={s.coloredText}>//</span> 9 команд{' '}
                        <span className={s.coloredText}>//</span> 45 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/dyfmPF6Lrks" className={s.textLink} target="_blank" rel="noreferrer">
                                RU Stream
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/L3kvU8AZ8no" className={s.textLink} target="_blank" rel="noreferrer">
                                ENG Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Grid Dynamics & Daxx </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>09.04.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/GIyUDcEsMX0" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/liKClGNgH2s" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/KsWfa_9FOwc" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Modulbank  </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>03.04.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/HO91f9DhV7k" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/NfbtbnFJkLo" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/GczpFXiGmYM" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Devexperts </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.02.2021 - 03.03.2021 </span>
                        <span className={s.coloredText}>//</span> 8 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/DMPbzRz0Vhs" className={s.textLink} target="_blank" rel="noreferrer">
                                RU Stream
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/vR9q3A0x3Vs" className={s.textLink} target="_blank" rel="noreferrer">
                                ENG Stream
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/0oLWOzs3tSs" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Intra </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>20.02.2021 </span>
                        <span className={s.coloredText}>//</span> 2 команды{' '}
                        <span className={s.coloredText}>//</span> 10 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/HvW3VysQy5k" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Auriga </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>19.02.2021 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/cClt9FDlC3A" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/1P9ZckAzOFM" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Lineate </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>13.02.2021 </span>
                        <span className={s.coloredText}>//</span> 3 команды{' '}
                        <span className={s.coloredText}>//</span> 15 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/einfLjD4sIE" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/uUqTpYs_JJs" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Dell Technologies </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.01.2021 </span>
                        <span className={s.coloredText}>//</span> 2 команды{' '}
                        <span className={s.coloredText}>//</span> 10 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/aLl7g1GuiuA" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Orion </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>23.10.2020 - 23.10.2020 </span>
                        <span className={s.coloredText}>//</span> 8 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/einfLjD4sIE" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                            </span>
                            <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/ceMhZsolvjY" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>MERA </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>15.07.2020 </span>
                        <span className={s.coloredText}>//</span> 8 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}> //</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/AdSLlTIBUBU" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>PropellerAds </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>12.06.2020 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/plXvpr72EJM" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/KPgSIL5_qow" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/VOBD7C1tbfI" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>             
                </div>
            </>
        ),
    },
    {   id: 'dota2',
        icon: <DotaIcon />,
        bg: DotaBg, count: 18,
        cyber: true, 
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        DOTA 2 сочетает в себе элементы ролевой игры и стратегии в реальном времени, где игроки управляют одним из множества героев с уникальными набором способностей. Основная задача — уничтожить главное здание соперника на карте (трон) и защитить своё.<br />
                        Формат соревнований - командный, 5 на 5.<br />
                        Игра бесплатная, доступна на Windows и Mac OS.<br />
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT CYBER GAMES </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by Work Sport Balance</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.10.2022 - 10.11.2022 </span>
                        <span className={s.coloredText}>//</span> 13 команд{' '}
                        <span className={s.coloredText}>//</span> 69 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLegMkFkgoXwYjGR-yeY34wyiUrevcUP3q" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>21.03.2022 - 03.04.2022 </span>
                        <span className={s.coloredText}>//</span> 11 команд{' '}
                        <span className={s.coloredText}>//</span> 58 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFHrdtmQXmhxwEcJBW6NczE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.11.2021 - 10.12.2021 </span>
                        <span className={s.coloredText}>//</span> 18 команд{' '}
                        <span className={s.coloredText}>//</span> 98 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jEhkc9Mq09387tgQtQCEJB1" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>15.10.2020 - 12.12.2020 </span>
                        <span className={s.coloredText}>//</span> 17 команд{' '}
                        <span className={s.coloredText}>//</span> 92 участника{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFQvYSWkkfPjzBtQBWe8atO" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>26.04.2020 - 08.05.2020 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 27 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFQvYSWkkfPjzBtQBWe8atO" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        «IT Cyber Games» - регулярный чемпионат среди ИТ компании.<br />
                        3 дисциплины: CS:GO, DOTA 2, League of Legends.<br />
                        География участников турнира: от Владивостока до Праги.<br />
                        Обычно чемпионат проходит 2 раза в год: зима-весна и осень-зима. Проходит в 2 этапа: групповая стадия (квалификация) и плей-офф. Все игры чемпионата транслируются в YouTube-канале Work Sport Balance<br />
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                         
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>AZUR GAMES </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                <div className={s.boldRow}>
                        <span className={s.coloredText}>22.04.2023 - 23.04.2023 </span>
                        <span className={s.coloredText}>//</span> 10 команд{' '}
                        <span className={s.coloredText}>//</span> 50 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/tmBcErZLAKE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/torXZq0pZ_Y" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/-RPN_NwgmnI" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div> 
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>23.04.2022 - 24.04.2022 </span>
                        <span className={s.coloredText}>//</span> 11 команд{' '}
                        <span className={s.coloredText}>//</span> 55 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/JQGdz7p-fMQ" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/Sl-tKk9in9Q" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>17.04.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/AH4vZ0ak5_E" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/Y4wVPXkU64Y" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/zjNEGyKXJ0c" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>         
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>06.06.2020 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/VLRasyi9NAc" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>             
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>SRG</div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>11.05.2023 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 29 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/-CZKg-kvJhA" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/3LnR5_280Gk" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>    
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Газпромнефть - Снабжение</div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>27.08.2022 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/zEOO-kHxALE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/Y28gGXLoipE" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>17.07.2022 </span>
                        <span className={s.coloredText}>//</span> 4 команд{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/kiAATkaFfSY" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/5G5nU23eFNQ" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>15.12.2021 </span>
                        <span className={s.coloredText}>//</span> 2 команды{' '}
                        <span className={s.coloredText}>//</span> 10 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/H0cTehy7t1U" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Space307  </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>20.02.2022 </span>
                        <span className={s.coloredText}>//</span> 3 команды{' '}
                        <span className={s.coloredText}>//</span> 15 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/zH0f_6uL36w" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Argus </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>18.02.2022 </span>
                        <span className={s.coloredText}>//</span> 3 команды{' '}
                        <span className={s.coloredText}>//</span> 15 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/j3zV1gSeDOI" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Veeam </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.04.2021 - 23.04.2021 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/QgNuly8f-_k" className={s.textLink} target="_blank" rel="noreferrer">
                                RU Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/z1g04lgkk2c" className={s.textLink} target="_blank" rel="noreferrer">
                                ENG Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/DDXwHTLoXx0" className={s.textLink} target="_blank" rel="noreferrer">
                                RU Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/X91K3p7ODuY" className={s.textLink} target="_blank" rel="noreferrer">
                                ENG Stream server 2
                            </a>
                        </span>

                    </div>    
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Lightmap & Royal Ark </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>06.03.2021 - 13.03.2021 </span>
                        <span className={s.coloredText}>//</span> 8 команд{' '}
                        <span className={s.coloredText}>//</span> 40 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/Tt_Gr8RNWgU" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/G_6AKq_gGQw" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>    
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>PropellerAds </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.02.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 25 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/6BWuidWESQc" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/6Qf0PNn1NLc" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                    </div>    
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Dell Technologies </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.01.2021 </span>
                        <span className={s.coloredText}>//</span> 2 команды{' '}
                        <span className={s.coloredText}>//</span> 10 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/R5tUcxYQi6w" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Grid Dynamics </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>22.09.2020 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/giKYmH4o38Y" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
            </>
        ),
    },
    {   id: 'league_of_legends',
        icon: <LolIcon />,
        bg: LolBg, count: 6,
        cyber: true, 
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        LoL — это стратегическая игра, в которой две команды могущественных чемпионов сражаются друг с другом, пытаясь уничтожить вражескую базу (нексус).<br />
                        Основной формат соревнований — командный, 5 на 5.<br />
                        Игра выделяется ярким дизайном, динамичностью и проработанной вселенной.<br />
                        Бесплатная, доступна на Windows и Mac OS.<br />
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>IT CYBER GAMES </div>
                    <div className={s.coloredText}>
                        <div className={s.contentSubTitle}>by Work Sport Balance</div>
                    </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>04.04.2022 - 17.04.2022 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 27 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jGPPtZXgW70z5CtqHUanqnn" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>13.12.2021 - 17.12.2021 </span>
                        <span className={s.coloredText}>//</span> 5 команд{' '}
                        <span className={s.coloredText}>//</span> 26 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtube.com/playlist?list=PLqhm1-rDY8jFuDHOmHLSWVvQ1WGdArnCh" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream playlist
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        «IT Cyber Games» - регулярный чемпионат среди ИТ компании.<br />
                        3 дисциплины: CS:GO, DOTA 2, League of Legends.<br />
                        География участников турнира: от Владивостока до Праги.<br />
                        Обычно чемпионат проходит 2 раза в год: зима-весна и осень-зима. Проходит в 2 этапа: групповая стадия (квалификация) и плей-офф. Все игры чемпионата транслируются в YouTube-канале Work Sport Balance<br />
                    </div>
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>AZUR GAMES</div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.06.2022 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/We3jkbsgnyg" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/1mp5rQk62ow" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream server 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/sHrqew3_l2Y" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>16.05.2021 </span>
                        <span className={s.coloredText}>//</span> 3 команды{' '}
                        <span className={s.coloredText}>//</span> 15 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/cal25PB4fns" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.06.2020 </span>
                        <span className={s.coloredText}>//</span> 4 команды{' '}
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/tsFXQuo_3us" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>   
                </div>
                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Veeam </div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>25.06.2022 </span>
                        <span className={s.coloredText}>//</span> 6 команд{' '}
                        <span className={s.coloredText}>//</span> 30 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/LHngmu21AuU" className={s.textLink} target="_blank" rel="noreferrer">
                                RU Stream
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/alKXk6eSCI4" className={s.textLink} target="_blank" rel="noreferrer">
                                ENG Stream
                            </a>
                        </span>
                    </div>    
                </div>

            </>
        ),
    },
    {
        id: 'fifa',
        icon: <FifaIcon />,
        bg: FifaBg,
        count: 1,
        disabled: false,
        cyber: true,
        cardContent: (
            <>
                
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        FIFA (она же с 2023 года EA Sports FC) уже много лет является самым популярным футбольным симулятором.<br />
                        Формат соревнований — одиночный (1 на 1).<br />
                        Флагманские игры серии (последняя версия - FIFA23) платные, но существуют и условно-бесплатные (FIFA Online).<br />
                        Доступны на Windows, PS, Xbox.<br />
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                         
                    </div>
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>SberAutoTech</div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>07.10.2021 </span>
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> LAN{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/DLbe9Q9Gxss" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'mortal_kombat',
        icon: <MkIcon />,
        bg: MkBg,
        count: 1,
        disabled: false,
        cyber: true,
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Mortal Kombat — легендарная серия файтингов.<br />
                        Основой игрового процесса являются поединки разнообразных персонажей, у каждого из которых свои отличительные способности и приемы, а также зрелищные добивания, или "фаталити".<br />
                        Формат соревнований — одиночный (1 на 1).<br />
                        Игра платная, доступна на Windows, PS, Xbox.<br />
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                         
                    </div>
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>SberAutoTech</div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>07.10.2021 </span>
                        <span className={s.coloredText}>//</span> 20 участников{' '}
                        <span className={s.coloredText}>//</span> LAN{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/DLbe9Q9Gxss" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        <br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'overwatch',
        icon: <OverwatchIcon />,
        bg: OverwatchBg,
        count: 1,
        disabled: false,
        cyber: true,
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Overwatch 2 — тактический шутер от первого лица в ярком фантастическом сеттинге. Разнообразные режимы (в одном турниром матче игроки сражаются сразу в нескольких из них), персонажи и способности на любой вкус.<br />
                        Основной формат соревнований — командный 5 на 5.<br />
                        Игра бесплатная, доступна на Windows, PS, Xbox.<br />
                    </div>
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Lightmap</div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>09.10.2021 </span>
                        <span className={s.coloredText}>//</span> 24 участника{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/p4AkPzXwVGw" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>    
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Ещё одной редкой игрой в наших кругах счиатется Overwatch 2.<br />
                        Игра жанра - командный шутер, события которого разворачиваются в недалеком будущем. Каждый матч — это 
                        напряженная схватка 6х6 между уникальными героями. Компания Lightmap захотела провести турнир по Overwatch 2. 
                        По итогам группы победителем турнира 1 место заняла команда "Overcats", 2 место "Хомячья сила", 3 место "t3". 
                        <br />Мы получили отличный опыт, игроки тимбилдинг и отличное настроение. И как всегда, хотелось бы побольше разных игр.<br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'quake',
        icon: <QuakeIcon />,
        bg: QuakeBg,
        count: 1,
        disabled: false,
        cyber: true,
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Quake - культовый шутер от первого лица и родоначальник киберспорта.<br />
                        Форматы соревнований — одиночный (1 на 1) и командный (2х2, 4х4).<br />
                        Актуальная версия для турниров - Quake Champions (бесплатная, доступна на Windows), но возможно использовать Quake 2 / 3 / Live.<br />
                    </div>
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>Xored</div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>17.07.2020 </span>
                        <span className={s.coloredText}>//</span> 9 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/1KGmKucqXXY" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        По сей день самой редкой дисциплиной, по которой мы проводим турниры, является Quake III Arena.<br />
                        Но мы с радостью провели такой турнир для компании Xored. 9 сотрудников компании сражались за титул 
                        чемпиона. Им стал игрок под ником "ASH". Это был классный опыт, и очень жаль, что так редко проходят 
                        турниры по таким играм. Мы с большим удовольствием проводили бы их. Кстати, в нашем "Гейм-пулле" больше 
                        20 игр, по которым мы можем провести турнир.<br />
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'valo',
        icon: <ValoIcon />,
        bg: ValoBg,
        count: 0,
        disabled: false,
        cyber: true,
        cardContent: (
            <>
                <div style={{ marginBottom: '50px' }}>
                    <div className={s.regularRow}>
                        Valorant — многопользовательский шутер от первого лица, в котором игроков ждут противостояния команд в формате 5х5 игроков.<br />
                        На выбор добтупны различные агенты с различными способностями, способные изменить ход игры.<br />
                        Игра бесплатная, доступна на Windows.<br />
                    </div>
                </div>

                <div className={s.contentTitleWrapper}>
                    <div className={s.contentTitle}>AZUR GAMES</div>
                </div>
                <div style={{ marginBottom: '21px' }}>
                    <div className={s.boldRow}>
                        <span className={s.coloredText}>24.06.2023 - 25.06.2023 </span>
                        <span className={s.coloredText}>//</span> 9 команд{' '}
                        <span className={s.coloredText}>//</span> 47 участников{' '}
                        <span className={s.coloredText}>//</span> Online{' '}
                        <span className={s.coloredText}>//</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/A6m-RetOU1g" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream 1
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://www.youtube.com/live/O8FoLdF9wmY" className={s.textLink} target="_blank" rel="noreferrer">
                                Stream 2
                            </a>
                        </span>
                        <span className={s.coloredText}>,</span>{' '}
                        <span className={s.coloredText}>
                            <a href="https://youtu.be/A1Iv8Q8uFis" className={s.textLink} target="_blank" rel="noreferrer">
                                Highlights
                            </a>
                        </span>
                    </div>    
                </div>
            </>
        ),
    },
    {
        id: 'fallguys',
        icon: <FallguysIcon />,
        bg: FallguysBg,
        count: 0,
        disabled: true,
        cyber: true,
        cardContent: <></>,
    },
    {
        id: 'assetto',
        icon: <AssettoIcon />,
        bg: AssettoBg,
        count: 0,
        disabled: true,
        cyber: true,
        cardContent: <></>,
    },
    {
        id: 'pubg',
        icon: <PubgIcon />,
        bg: PubgBg,
        count: 0,
        disabled: true,
        cyber: true,
        cardContent: <></>,
    },
    {
        id: 'tanks',
        icon: <TanksIcon />,
        bg: TanksBg,
        count: 0,
        disabled: true,
        cyber: true,
        cardContent: <></>,
    },
];

export const typeCards: Card[] = [
    {
        front: 'ВНУТРИКОРПОРАТИВНОЕ',
        back: 'Соревнование между сотрудниками одной компании или между командами структурных подразделений',
    },
    {
        front: 'МЕЖКОРПОРАТИВНОЕ',
        back: 'Спортивное противостояние между игроками или командами из разных компаний, например, дружеский футбольный матч',
    },
    {
        front: 'МАРКЕТИНГОВОЕ',
        back: 'Хотите подогреть интерес аудитории к вашей рекламной кампании? Спортивный праздник точно привлечёт внимание, если ваша ЦА любит активный отдых',
    },
    {
        front: 'СПОНСОРСКОЕ',
        back: 'Выступаете спонсором некоммерческого проекта? Хотите, чтобы ваше участие запомнили? Устройте для своих подопечных незабываемый день спорта с нами',
    },
    {
        front: 'ПАРТНЕРСКОЕ',
        back: 'Проведение дружеской спортивной встречи между сотрудниками вашей компании и бизнес-партнёрами укрепит лояльные отношения',
    },
    {
        front: 'КЛИЕНТСКОЕ',
        back: 'Лучший подарок - это новые впечатления: азарт, драйв, радость и вкус победы… Устроим для ваших клиентов праздник, который точно запомнится!',
    },
];

export const formatCards: Card[] = [
    {
        front: 'ТУРНИР',
        back: 'Состязания между командами или отдельными игроками, с турнирной таблицей и призовыми местами',
    },
    {
        front: 'ЧЕМПИОНАТ',
        back: 'Соревнование на звание чемпиона какого-либо уровня (отдела, департамента, группы компаний и т.д.) в определённой спортивной дисциплине',
    },
    {
        front: 'ШОУ-МАТЧ',
        back: 'Зрелищная битва с приглашёнными звёздами: популярными стримерами, блогерами и PRO игроками',
    },
];

export const approachItems: ListItem[] = [
    { title: 'Заполнение брифинга' },
    { title: 'Подготовка коммерческого предложения' },
    { title: 'Согласование всех аспектов проведения турнира' },
    { title: 'Разработка индивидуального регламента' },
    { title: 'Определение оптимальной турнирной сетки исходят из количества участников' },
    { title: 'Коммуникация и поддержка участников на этапе подготовки и проведения' },
    { title: 'Подготовка бригады квалифицированных судей' },
    { title: 'Создание баннеров для социальных сетей' },
    { title: 'Разработка ленгдинга спортивного мероприятия' },
    { title: 'Написание писем, анонсов' },
    { title: 'Проведение спортивного мероприятия' },
    { title: 'Получение обратной связи Ретроспектива' },
];
