import classNames from 'classnames';
import { useState } from 'react';
import Lottie from 'react-lottie';

import { slides } from './data';
import s from './Format.module.scss';

const animationDefaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export default function Format() {
    const [activeSlideKey, setActiveSlideKey] = useState(0);
    const activeSlide = slides.find((slide) => slide.key === activeSlideKey);
    return (
        <section className={classNames('section', s.formatSection)}>
            <div className="wrapper">
                <div className="anchor-place" id="format"></div>
                <h2 className={classNames('section__title', s.sectionTitle)}>Формат</h2>
                <div className={s.mainText}>
                    Главная фишка киберспортивных соревнований — вы можете объединить за игрой как
                    сотрудников, живущих в разных городах и странах в ONLINE, так и тех, кто
                    работает в одной локации в формате LAN. В любом случае участники кибертурнира
                    переживут целую бурю ярких эмоций и надолго запомнят этот день. А трансляцию
                    может посмотреть любой желающий — клиенты, родственники, коллеги играющих, —
                    кому вы дадите ссылку.
                </div>
                <div className={s.formatSlider}>
                    <div className={s.slideIconWrapper}>
                        <Lottie
                            options={{
                                ...animationDefaultOptions,
                                animationData: activeSlide!.animatedIcon,
                            }}
                            height={250}
                            width={250}
                            isClickToPauseDisabled={true}
                        />
                    </div>

                    <div className={s.content}>
                        <div className={s.buttons}>
                            {slides.map((slide) => (
                                <div
                                    className={classNames(
                                        s.button,
                                        activeSlideKey === slide.key && s.buttonActive,
                                    )}
                                    onClick={() => {
                                        setActiveSlideKey(slide.key);
                                    }}
                                >
                                    {slide.title}
                                </div>
                            ))}
                        </div>
                        <div className={s.textContent}>{activeSlide?.text}</div>
                    </div>
                </div>
            </div>
        </section>
    );
}
