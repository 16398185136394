import classNames from 'classnames';

import s from './Approach.module.scss';

export interface ListItem {
    title: string;
}

export default function Approach(props: { listItems: ListItem[] }) {
    const { listItems } = props;
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <div className="anchor-place" id="structure"></div>
                <h2 className="section__title">СТРУКТУРА РАБОТЫ</h2>
                {/* <CardsList cards={props.cards} /> */}
                <div className={s.list}>
                    {listItems.map((listItem, index) => (
                        <div className={s.item}>
                            <div className={s.number}>{`${index + 1}.`}</div>
                            <div className={s.text}>{listItem.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
