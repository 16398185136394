import s from '../StreamTypes.module.scss';
import { ReactComponent as PodcastImg } from './images/Podcast.svg';

export function Podcast() {
    return (
        <div className={s.itemWrapper}>
            <div className={s.content}>
                <ul className={s.services}>
                    <li className={s.service}>Индивидуальное графическое оформление</li>
                    <li className={s.service}>Создание анимаций и стингеров</li>
                    <li className={s.service}>
                        Стриминг на открытые платформы: YouTube, VK, Twitch и др.
                    </li>
                    <li className={s.service}>Стриминг на специализированные платформы: Facecast, Restream и др.</li>
                    <li className={s.service}>Трансляция в высоком качестве: 4k60fps</li>
                    <li className={s.service}>Профессиональное вещательное оборудование</li>
                    <li className={s.service}>Профессиональное звуковое оборудование</li>
                    <li className={s.service}>Микрофоны Shure SM7b</li>
                    <li className={s.service}>Профессиональное освещение</li>
                    <li className={s.service}>Индивидуальное оформление студии</li>
                </ul>
                <div className={s.tags}>
                    <div className={s.tag}>офлайн</div>
                    <div className={s.tag}>онлайн</div>
                    <div className={s.tag}>гибрид</div>
                </div>
            </div>
            <div className={s.pic}>
                <PodcastImg />
            </div>
        </div>
    );
}
