import LanAnimation from './LanAnimation.json';
import OnlineAnimation from './OnlineAnimation.json';

export const slides = [
    {
        title: 'online',
        key: 0,
        text: (
            <span>
                ONLINE — это формат проведения киберспортивного турнира удаленных друг от друга
                игроков по сети интернет. Для взаимодействия судей, организаторов и игроков между
                друг другом используется мессенджер DISCORD. Вариант проведения киберспортивного
                турнира в ONLINE идеально подходит для распределенных команд, когда сотрудники из
                разных городов могут принять совместное участие в общей нерабочей активности. Для
                международных компаний важно одновременно поддерживать несколько языков общения.
                Интернациональная судейская бригада сможет общаться с игроками на комфортном им
                языке, а болельщики получат мультиязычную трансляцию. При выборе игровых серверов
                наши специалисты учитывают географию участников, чтобы пинг был примерно одинаковый
                у всех.
            </span>
        ),
        animatedIcon: OnlineAnimation,
    },
    {
        title: 'offline',
        key: 1,
        text: (
            <span>
                LAN (Local Area Network) — это формат игры по локальной сети, когда игроки находятся
                в одном помещении и могут общаться между собой вживую. Саму игру и происходящее в
                зале можно также транслировать на стриминговые платформы, как и при проведении
                онлайн-турнира. В формате LAN можно провести финальные игры турнира, первым этапом
                которого была ONLINE квалификация. LAN-турнир можно провести в современном
                киберклубе или построить арену прямо у вас в офисе. Если хочется устроить
                впечатляющее масштабное мероприятие, то мы можем организовать киберспортивный
                буткемп с выездом за город. За несколько дней ваши сотрудники смогут погрузиться в
                атмосферу соревнований, отвлечься от серости будней и рабочей рутины и сохранить
                впечатления от такого корпоратива на долгие годы.
            </span>
        ),
        animatedIcon: LanAnimation,
    },
];
